import { AppContent } from '../AppContent';

const makePaymentPhone: AppContent['makePaymentPhone'] = {
  backButtonLabel: 'Back',
  heading: 'Pay by phone',
  notification: {
    paragraph1:
      'The most you can overpay by phone in one go is £60,000, but you can make more than one payment per day.',
    paragraph2:
      "It's also best to check the payment limits on the current account you're paying from, before making any large payments.",
  },
  makePaymentSection: {
    heading: 'To make your payment',
    paragraph1: 'Call our automated phone line on ',
    contactPhoneNumber: '0345 727 3747',
    afterContactPhoneNumber: '.',
    paragraph2:
      "You'll need to use your mortgage account number when making the payment.",
  },
  returnToOverviewLink: 'Return to mortgage overview',
};

export default makePaymentPhone;
