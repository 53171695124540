import React from 'react';

import { Dialog, Paragraph } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import { RedemptionContent } from '../../RedemptionPage.config';

interface ErcDialogProps {
  open?: boolean;
  onClose: () => void;
}

function ErcDialog({ open, onClose }: ErcDialogProps) {
  const { ercDialog } = useContent<RedemptionContent>();

  const { heading, paragraph1, paragraph2, paragraph3, paragraph4 } = ercDialog;

  return (
    <Dialog
      title={heading}
      open={open}
      onClose={onClose}
      data-testid="erc-info-dialog"
    >
      <Paragraph>{paragraph1}</Paragraph>
      <Paragraph>{paragraph2}</Paragraph>
      <Paragraph>{paragraph3}</Paragraph>
      <Paragraph marginBottom="none">{paragraph4}</Paragraph>
    </Dialog>
  );
}

export default ErcDialog;
