import React, { ReactElement } from 'react';

import {
  Container,
  BackgroundProvider,
  IconPadlock,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { AppLayoutContent } from 'client/components/appLayout/AppLayoutContent.config';
import { useTheme } from 'styled-components';

import {
  StyledHeader,
  StyledInnerContainer,
  StyledBirminghamMidshiresLogo,
  StyledSafeSecureContainer,
  StyledSafeSecureText,
  StyledSafeSecureIconContainer,
} from './HeaderComponent.styled';

export default function HeaderComponent({ logo }): ReactElement {
  const {
    header: { safeAndSecureSection, accessibleLogoDescription },
  } = useContent<AppLayoutContent>();

  const theme: any = useTheme();

  return (
    <StyledHeader>
      <BackgroundProvider value={{ cssValue: theme.header_color_background }}>
        <Container>
          <StyledInnerContainer>
            <StyledBirminghamMidshiresLogo
              src={logo}
              alt={accessibleLogoDescription.text}
            />
            <StyledSafeSecureContainer>
              <StyledSafeSecureText>
                {safeAndSecureSection.text}
                <StyledSafeSecureIconContainer>
                  <IconPadlock
                    color="default1"
                    iconSize="lg"
                    size="s1"
                    data-testid="header-safe-and-secure-icon"
                  />
                </StyledSafeSecureIconContainer>
              </StyledSafeSecureText>
            </StyledSafeSecureContainer>
          </StyledInnerContainer>
        </Container>
      </BackgroundProvider>
    </StyledHeader>
  );
}
