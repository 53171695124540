import styled from 'styled-components';

export const BreakoutContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing_05};
  padding-top: ${({ theme }) => theme.spacing_04};
  padding-bottom: ${({ theme }) => theme.spacing_04};
  background-color: white;
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
