import {
  Action,
  configureStore,
  ConfigureStoreOptions,
  ThunkAction,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import api from './services/api';
import auth from './slices/auth';
import overview from './slices/overview';

export type ThunkLoadingState = 'idle' | 'pending' | 'succeeded' | 'failed';

export const setupStore = (
  options?: ConfigureStoreOptions['preloadedState'] | undefined,
) => {
  const configuredStore = configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
      [overview.reducerPath]: overview.reducer,
      [auth.reducerPath]: auth.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware),
    ...options,
  });

  setupListeners(configuredStore.dispatch);

  return configuredStore;
};

const getCookie = (name: string): string | null => {
  const match = document.cookie.match(RegExp(`(?:^|;\\s*)${name}=([^;]*)`));
  return match ? match[1] : null;
};

export const store = setupStore({
  preloadedState: {
    auth: {
      // If you have this token you're considered 'logged in' - but wouldnt be able to retrieve data without a valid auth token
      isLoggedIn: !!getCookie(
        window.appConfig.cookieName ?? '__Secure_BM_DC_ST',
      ),
    },
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
