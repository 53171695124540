import React, { useEffect } from 'react';

import {
  Box,
  Button,
  ButtonPattern,
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Hr,
  Link,
  List,
  ListItem,
  Main,
  Paragraph,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import * as routes from 'client/routes/manifest';
import OnceMadePaymentAccordion from 'client/routes/redemption/components/OnceMadePaymentAccordion/OnceMadePaymentAccordion';
import { Overview } from 'client/services/types/overview';
import { useAppSelector } from 'client/store';
import { logTealiumButtonClickEvent, logTealiumNavEvent } from 'client/tealium';

import { MakePaymentBankTransferContent } from './MakePaymentBankTransferPage.config';
import { StyledContainer } from '../MakePaymentPage.styled';

function MakePaymentBankTransferPage() {
  const {
    heading,
    beforePaymentSection,
    importantToKnowSection,
    paymentDetailsSection,
    backButtonLabel,
    returnToOverviewLink,
  } = useContent<MakePaymentBankTransferContent>();
  const navigate = useNavigate();
  const { overview } = useAppSelector<Overview>((state) => state.overview);
  const { mortgageAccountNumber } = overview || {};

  useEffect(() => {
    logTealiumNavEvent(
      'Redemption - Pay by bank transfer',
      '3',
      undefined,
      'Pre-Application',
    );
  }, []);

  return (
    <Main>
      <StyledContainer>
        <Link
          as="button"
          iconPosition="left"
          onClick={() => {
            logTealiumButtonClickEvent('button/back');
            navigate(routes.MakePayment);
          }}
          data-testid="back-button-link"
        >
          {backButtonLabel}
        </Link>
      </StyledContainer>
      <Hr />

      <Grid>
        <GridItem xs={12} lg={8}>
          <Heading as="h1" size="s5" data-testid="page-heading">
            {heading}
          </Heading>

          <ContentGroup>
            <Heading as="h2" size="s2" data-testid="before-payment-heading">
              {beforePaymentSection.heading}
            </Heading>
            <List>
              {beforePaymentSection.list.map((item, idx) => (
                <ListItem
                  // eslint-disable-next-line react/no-array-index-key -- Static, fixed length content list
                  key={`before-payment-list-${idx}`}
                  data-testid={`before-payment-list-${idx}`}
                >
                  {item}
                </ListItem>
              ))}
            </List>
          </ContentGroup>

          <ContentGroup>
            <Heading as="h2" size="s2" data-testid="important-to-know-heading">
              {importantToKnowSection.heading}
            </Heading>
            <List>
              {importantToKnowSection.list.map((item, idx) => (
                <ListItem
                  // eslint-disable-next-line react/no-array-index-key -- Static, fixed length content list
                  key={`important-to-know-list-${idx}`}
                  data-testid={`important-to-know-list-${idx}`}
                >
                  {item}
                </ListItem>
              ))}
            </List>
          </ContentGroup>

          <Box>
            <Heading as="h2" size="s3" data-testid="payment-details-heading">
              {paymentDetailsSection.heading}
            </Heading>

            <Paragraph marginBottom="none" data-testid="account-name-label">
              {paymentDetailsSection.accountNameLabel}
            </Paragraph>
            <Paragraph weight="bold" data-testid="account-name">
              {paymentDetailsSection.accountName}
            </Paragraph>

            <Paragraph marginBottom="none" data-testid="sort-code-label">
              {paymentDetailsSection.sortCodeLabel}
            </Paragraph>
            <Paragraph weight="bold" data-testid="sort-code">
              {paymentDetailsSection.sortCode}
            </Paragraph>

            <Paragraph marginBottom="none" data-testid="account-number-label">
              {paymentDetailsSection.accountNumberLabel}
            </Paragraph>
            <Paragraph weight="bold" data-testid="account-number">
              {paymentDetailsSection.accountNumber}
            </Paragraph>

            <Paragraph
              marginBottom="none"
              data-testid="payment-reference-label"
            >
              {paymentDetailsSection.paymentReferenceLabel}
            </Paragraph>
            <Paragraph
              weight="bold"
              marginBottom="none"
              data-testid="payment-reference"
            >
              {mortgageAccountNumber
                ? `${mortgageAccountNumber}00`
                : paymentDetailsSection.paymentReferenceAlternativeText}
            </Paragraph>
          </Box>

          <OnceMadePaymentAccordion />

          <ButtonPattern>
            <Button
              iconPosition="left"
              onClick={() => {
                logTealiumButtonClickEvent(
                  'button/return-to-mortgage-overview',
                );
                navigate(routes.MortgageOverview);
              }}
              data-testid="return-to-overview-button"
            >
              {returnToOverviewLink}
            </Button>
          </ButtonPattern>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default MakePaymentBankTransferPage;
