import { AppContent } from '../AppContent';

const borrowMore: AppContent['borrowMore'] = {
  backButtonLabel: 'Back',
  title: 'Ready to borrow more?',
  para1: "You'll need to speak to a mortgage broker to find your next deal.",
  para2Title: 'Not got a mortgage broker?',
  link: {
    text: 'Search for one here',
    url: 'https://www.unbiased.co.uk/',
  },
  riskStatement:
    "You could lose your property if you don't keep up your mortgage repayments.",
};

export default borrowMore;
