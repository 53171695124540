import React, { useEffect } from 'react';

import {
  Text,
  Heading,
  IconChevronRight,
  IconCreditCard,
  Link,
  Main,
  IconPhone,
  Hr,
  Grid,
  GridItem,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import * as routes from 'client/routes/manifest';
import { logTealiumButtonClickEvent, logTealiumNavEvent } from 'client/tealium';

import { MakePaymentContent } from './MakePaymentPage.config';
import {
  StyledContainer,
  BoxLink,
  BoxLinkSpan,
  BoxLinkTextContainer,
} from './MakePaymentPage.styled';

function MakePaymentPage() {
  const { heading, bankTransferCard, byPhoneCard, backButtonLabel } =
    useContent<MakePaymentContent>();

  const navigate = useNavigate();

  useEffect(() => {
    logTealiumNavEvent(
      'Redemption - How do you want to pay?',
      '2',
      undefined,
      'Pre-Application',
    );
  }, []);

  return (
    <Main>
      <StyledContainer>
        <Link
          as="button"
          onClick={() => {
            logTealiumButtonClickEvent('button/back');
            navigate(routes.Redemption);
          }}
          iconPosition="left"
          data-testid="back-button-link"
        >
          {backButtonLabel}
        </Link>
      </StyledContainer>
      <Hr />

      <Grid>
        <GridItem xs={12} lg={8}>
          <Heading size="s5">{heading}</Heading>
          <BoxLink
            to={routes.MakePaymentBankTransfer}
            data-testid="bank-transfer-link"
            onClick={() => {
              logTealiumButtonClickEvent('button/bank-transfer');
            }}
          >
            <BoxLinkSpan>
              <IconCreditCard trim iconSize="lg" color="brand" />
              <BoxLinkTextContainer>
                <Text color="brand" weight="bold">
                  {bankTransferCard.title}
                </Text>
                <Text size="s1">{bankTransferCard.info}</Text>
              </BoxLinkTextContainer>
              <IconChevronRight trim iconSize="md" color="brand" />
            </BoxLinkSpan>
          </BoxLink>
          <BoxLink
            to={routes.MakePaymentPhone}
            data-testid="pay-by-phone-link"
            onClick={() => {
              logTealiumButtonClickEvent('button/by-phone');
            }}
          >
            <BoxLinkSpan>
              <IconPhone trim iconSize="lg" color="brand" />
              <BoxLinkTextContainer>
                <Text color="brand" weight="bold">
                  {byPhoneCard.title}
                </Text>
                <Text size="s1">{byPhoneCard.info}</Text>
              </BoxLinkTextContainer>
              <IconChevronRight trim iconSize="md" color="brand" />
            </BoxLinkSpan>
          </BoxLink>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default MakePaymentPage;
