import React from 'react';

import { Box, Heading, Paragraph, Text } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import { MortgageOverviewPageContent } from '../../MortgageOverviewPage.config';

function PaymentHelpPanel() {
  const { paymentHelpPanel } = useContent<MortgageOverviewPageContent>();

  return (
    <Box bgColor="neutral2" data-testid="overview-payment-help-panel">
      <Heading as="h2" size="s4">
        {paymentHelpPanel.heading}
      </Heading>
      <Paragraph>{paymentHelpPanel.paragraph1}</Paragraph>
      <Paragraph>
        <Text>{paymentHelpPanel.paragraph2}</Text>
        <Text weight="bold"> {paymentHelpPanel.helpNumber}</Text>.
      </Paragraph>
      <Paragraph>{paymentHelpPanel.paragraph3}</Paragraph>
      <Paragraph>{paymentHelpPanel.paragraph4}</Paragraph>
      <Paragraph marginBottom="none">{paymentHelpPanel.paragraph5}</Paragraph>
    </Box>
  );
}

export default PaymentHelpPanel;
