import { AppContent } from '../AppContent';

const redemptionError: AppContent['redemptionError'] = {
  notification: {
    heading: "Sorry, we can't show your amount owing figure right now.",
    text: 'Please try again later.',
  },
  returnLinkLabel: 'Return to mortgage overview',
};

export default redemptionError;
