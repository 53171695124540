import React, { useEffect, useState } from 'react';

import {
  Main,
  Button,
  Heading,
  Checkbox,
  Spinner,
  Paragraph,
  Notification,
  ListItem,
  List,
  DateField,
  Link,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useLocation, useNavigate } from '@interstellar/react-app-routing';
import { ErrorSummaryBuilder } from 'client/components/errorSummaryBuilder';
import { useFormMutation } from 'client/services/api';
import {
  getJourneyStep,
  logTealiumBlurEvent,
  logTealiumButtonClickEvent,
  logTealiumNavEvent,
} from 'client/tealium';
import isValidAccountNumber from 'client/validation/isValidAccountNumber';
import isValidDate from 'client/validation/isValidDate';
import isValidPostcode from 'client/validation/isValidPostcode';
import isValidTelephoneNumber from 'client/validation/isValidTelephoneNumber';
import { Field, Form, Formik } from 'formik';

import { RedemptionStatementContent } from './RedemptionStatementPage.config';
import * as routes from '../../manifest';
import RequestForm from '../components/requestForm/RequestForm';
import { RequestFormContent } from '../components/requestForm/RequestForm.config';

interface FormValues {
  title: string;
  firstName: string;
  lastName: string;
  address: string;
  postcode: string;
  accountNumber: string;
  telephone: string;
  chosenDate: { day: string; month: string; year: string };
  confirmationCheckbox: boolean;
}

export default function RedemptionStatementPage() {
  const location = useLocation();

  useEffect(() => {
    logTealiumNavEvent(
      'Statement request forms',
      getJourneyStep(location),
      undefined,
      'Pre-Application',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let focusTimerStart: number;

  const handleTimerEnd = (fieldName: string) => {
    logTealiumBlurEvent(fieldName, Date.now() - focusTimerStart);
  };

  const [form, { isLoading: isFormLoading }] = useFormMutation();

  const navigate = useNavigate();

  const [showSubmissionError, setShowSubmissionError] =
    useState<boolean>(false);
  const [showValidationError, setShowValidationError] =
    useState<boolean>(false);

  const {
    pageTitle,
    validationErrorTitle,
    requestInformation,
    chosenDateText,
    chosenDateError,
    confirmationCheckboxText,
    confirmationCheckboxError,
  } = useContent<RedemptionStatementContent>();
  const requestForm = useContent<RequestFormContent>();

  const {
    formErrorNotification: { errorNotificationHeading, errorNotificationBody },
  } = requestForm;

  const validateForm = (values: FormValues) => {
    const errors: Partial<{
      title: string;
      firstName: string;
      lastName: string;
      address: string;
      postcode: string;
      accountNumber: string;
      telephone: string;
      chosenDate: string;
      confirmationCheckbox: string;
    }> = {};

    const {
      title,
      firstName,
      lastName,
      address,
      postcode,
      accountNumber,
      telephone,
      chosenDate,
      confirmationCheckbox,
    } = values;

    if (!title) {
      errors.title = requestForm.errors.title;
    }

    if (!firstName) {
      errors.firstName = requestForm.errors.firstName;
    }

    if (!lastName) {
      errors.lastName = requestForm.errors.lastName;
    }

    if (!accountNumber) {
      errors.accountNumber = requestForm.errors.accountNumber;
    } else if (!isValidAccountNumber(accountNumber)) {
      errors.accountNumber = requestForm.errors.invalidAccountNumber;
    }

    if (!address) {
      errors.address = requestForm.errors.address;
    }

    if (!postcode) {
      errors.postcode = requestForm.errors.postcode;
    } else if (!isValidPostcode(postcode)) {
      errors.postcode = requestForm.errors.invalidPostcode;
    }

    if (!telephone) {
      errors.telephone = requestForm.errors.telephone;
    } else if (!isValidTelephoneNumber(values.telephone)) {
      errors.telephone = requestForm.errors.invalidTelephone;
    }

    const { day, month, year } = chosenDate;
    if (
      !day ||
      !month ||
      !year ||
      !isValidDate(parseInt(day, 10), parseInt(month, 10), parseInt(year, 10))
    ) {
      errors.chosenDate = chosenDateError;
    }

    if (!confirmationCheckbox) {
      errors.confirmationCheckbox = confirmationCheckboxError;
    }

    return errors;
  };

  const hasEmptyFields = (values: FormValues) =>
    Object.values(values).some((value) => value === '');

  return (
    <Main>
      {isFormLoading && <Spinner />}
      <Link
        iconPosition="left"
        as="button"
        data-testid="back-button-link"
        onClick={() => {
          logTealiumButtonClickEvent('button/back');
          navigate(routes.MortgageOverview);
        }}
      >
        Back
      </Link>
      <Heading
        as="h1"
        size="s7"
        data-testid="redemption-statement-page-heading"
        marginTop="03"
      >
        {pageTitle}
      </Heading>
      <Formik
        initialValues={{
          title: '',
          firstName: '',
          lastName: '',
          address: '',
          postcode: '',
          accountNumber: '',
          telephone: '',
          chosenDate: {
            day: '',
            month: '',
            year: '',
          },
          confirmationCheckbox: false,
        }}
        validate={validateForm}
        onSubmit={async (values: FormValues) => {
          const submitSuccessful = await form({
            personalDetails: { ...values },
          });

          if ('data' in submitSuccessful) {
            navigate(routes.SuccessfulRequest);
          } else {
            setShowSubmissionError(true);
            window.scrollTo({ top: 140, behavior: 'smooth' });
          }
        }}
      >
        {({ values, setValues, isValid, touched, errors, handleBlur }) => (
          <Form noValidate>
            <ErrorSummaryBuilder
              errors={errors}
              isVisible={showValidationError && !isValid}
              errorTitle={validationErrorTitle}
            />
            {showSubmissionError && (
              <Notification
                heading={errorNotificationHeading}
                sentiment="critical"
                data-testid="redemption-statement-submission-error-notification"
              >
                <Paragraph>{errorNotificationBody.errorMessageLine1}</Paragraph>
                <Paragraph>{errorNotificationBody.errorMessageLine1}</Paragraph>
                <Paragraph>{errorNotificationBody.errorMessageLine1}</Paragraph>
              </Notification>
            )}
            <Paragraph>{requestInformation.openingParagraph}</Paragraph>
            <Heading as="h2" size="s3" marginBottom="03">
              {requestInformation.listHeading}
            </Heading>
            <List>
              {requestInformation.sectionListItems.map((item: string) => (
                <ListItem key={item}>{item}</ListItem>
              ))}
            </List>
            <RequestForm
              handleBlur={handleBlur}
              values={values}
              touched={touched}
              errors={errors}
              requestFormFieldLabels={requestForm}
            />
            <Field
              label={chosenDateText}
              name="chosenDate"
              day={values.chosenDate.day}
              month={values.chosenDate.month}
              year={values.chosenDate.year}
              data-testid="request-form-chosen-date-field"
              onChange={(chosenDate: {
                day: string;
                month: string;
                year: string;
              }) => {
                setValues({ ...values, chosenDate });
              }}
              error={
                (touched.chosenDate ||
                  touched['chosenDate-day'] ||
                  touched['chosenDate-month'] ||
                  touched['chosenDate-year']) &&
                errors.chosenDate
              }
              as={DateField}
              marginBottom="05"
              onFocus={() => {
                focusTimerStart = Date.now();
              }}
              onBlur={(e) => {
                handleBlur(e);
                handleTimerEnd('chosenDate');
              }}
            />
            <Field
              name="confirmationCheckbox"
              as={Checkbox}
              label={confirmationCheckboxText}
              error={
                touched.confirmationCheckbox && errors.confirmationCheckbox
              }
              value={values.confirmationCheckbox}
              data-testid="request-form-confirmation-checkbox"
              marginBottom="05"
              onClick={() => {
                logTealiumButtonClickEvent('button/confirm');
              }}
            />
            <Button
              type="submit"
              data-testid="request-form-submit-button"
              onClick={() => {
                logTealiumButtonClickEvent('button/submit');
                if (!isValid || hasEmptyFields(values)) {
                  setShowValidationError(true);
                  window.scrollTo({ top: 140, behavior: 'smooth' });
                }
              }}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Main>
  );
}
