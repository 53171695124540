import React from 'react';

import { Strong } from '@constellation/core';

import { AppContent } from '../AppContent';

const requestForm: AppContent['requestForm'] = {
  titleFieldLabel: 'Title',
  firstNameFieldLabel: 'Your first name',
  lastNameFieldLabel: 'Your last name',
  addressFieldLabel: 'Property address',
  postcodeFieldLabel: 'Postcode',
  accountNumberFieldLabel: 'Mortgage account number',
  telephoneFieldLabel: 'Telephone number',
  errors: {
    title: 'Please confirm your title',
    firstName: 'Please confirm your first name',
    lastName: 'Please confirm your last name',
    address: `Please confirm the first line of the property's address`,
    postcode: 'Please confirm the postcode',
    accountNumber: 'Please confirm the mortgage account number',
    telephone: 'Please confirm your telephone number',
    invalidPostcode: 'Please enter a valid UK postcode',
    invalidAccountNumber:
      'Please enter a valid 14 - digit mortgage account number',
    invalidTelephone: 'Please enter a valid Telephone number',
  },
  formErrorNotification: {
    errorNotificationHeading: `This service isn't available at the moment.`,
    errorNotificationBody: {
      errorMessageLine1: `We're sorry but we're having some technical problems. Please try again later.`,
      errorMessageLine2: (
        <>
          If you find the same issue when you come back, please call us on{' '}
          <Strong>0345 300 2627.</Strong>
        </>
      ),
      errorMessageLine3: `Our lines are open Monday - friday 8am - 8pm, Saturday 9am - 1pm. We're closed on Sundays and Bank Holidays.`,
    },
  },
};

export default requestForm;
