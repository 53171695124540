import { Text } from '@constellation/core';
import styled, { StyledComponent } from 'styled-components';

export const StyledHeader = styled.header`
  background-color: ${({ theme }) => theme.header_color_background};
  margin-bottom: ${({ theme }) => theme.spacing_05};
  padding: ${({ theme }) => theme.spacing_05} 0;
`;

export const StyledSafeSecureText: StyledComponent<typeof Text, any> = styled<
  typeof Text
>(Text)`
  ${({ theme }: any) => theme.cssPrefix} {
    display: flex;

    font-size: ${({ theme }) => theme.typography_text_font_size_s1};
    font-weight: ${({ theme }) => theme.typography_font_weight_normal_css};
    line-height: ${({ theme }) => theme.typography_text_line_height_s1_css};
    transition-duration: ${({ theme }: any) => theme.transition_duration_fast};

    @media (min-width: ${({ theme }: any) => theme.breakpoint_sm}) {
      font-size: ${({ theme }) => theme.typography_text_font_size_s2};
      font-weight: ${({ theme }) => theme.typography_font_weight_bold_css};
      line-height: ${({ theme }) => theme.typography_text_line_height_s2_css};
    }
  }
`;

export const StyledSafeSecureContainer = styled.div`
  ${({ theme }: any) => theme.cssPrefix} {
    align-items: center;
    background: 'none';
    border: none;
    display: flex;
    justify-content: space-between;
    padding-left: ${({ theme }: any) => theme.spacing_03};
    padding-right: ${({ theme }: any) => theme.spacing_03};
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const StyledSafeSecureIconContainer = styled.div`
  ${({ theme }: any) => theme.cssPrefix} {
    align-items: center;
    display: flex;
    margin-left: ${({ theme }: any) => theme.spacing_02};
  }
`;

export const StyledInnerContainer = styled.div`
  ${({ theme }: any) => theme.cssPrefix} {
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledBirminghamMidshiresLogo = styled.img`
  ${({ theme }: any) => theme.cssPrefix} {
    width: 7rem;
    transition-duration: ${({ theme }: any) => theme.transition_duration_fast};

    @media (min-width: ${({ theme }: any) => theme.breakpoint_sm}) {
      width: 10.5rem;
    }

    @media (min-width: ${({ theme }: any) => theme.breakpoint_md}) {
      width: 14rem;
    }
  }
`;
