import { AppContent } from '../AppContent';

const productTransfer: AppContent['productTransfer'] = {
  backButtonLabel: 'Back',
  title: "It's time to switch your deal.",
  titleIntro: "You'll need to speak to a mortgage broker if you're keeping your property as a buy to let",
  brokerTitle: 'Not got a mortgage broker?',
  brokerLink: {
    text: 'Search for a mortgage broker here',
    url: 'https://www.unbiased.co.uk/',
  },
  contactUsTitle: "You'll need to talk to us if you have:",
  contactUsParagraph1: "A residential mortgage or you're planning to move yourself or a family member into a rented property in the next 2 years. Call us on",
  contactUsNumber1: {
    phoneNumber: '0345 608 0263',
  },
  contactUsParagraph2: "2 years or less left to pay on your mortgage. Call us on",
  contactUsNumber2: {
    phoneNumber: '0800 056 2980',
  },
  contactUsAnythingElse: "To talk to us about anything else to do with your mortgage, please call",
  contactUsAnythingElseNumber: {
    phoneNumber: '0345 300 2627',
  },
  wereHereTitle: "We're here",
  contactMondayToFriday: "Monday to Friday:",
  contactMondayToFridayTime: "8am-8pm.",
  contactSaturday: "Saturdays:",
  contactSaturday1: {
    number: "0345 608 0263",
    time: "8am-4pm"
  },
  contactSaturday2: {
    number: "0800 056 2980",
    time: "8am-5pm"
  },
  contactSaturday3: {
    number: "0345 300 2627",
    time: "8am-1pm",
  },
  linesOpeningTime: "We're closed on Sundays and bank holidays."
};

export default productTransfer;
