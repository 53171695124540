import { AppContent } from '../AppContent';

const redemption: AppContent['redemption'] = {
  backButtonLabel: 'Back',
  heading: 'Paying off your mortgage',
  dateDialog: {
    heading: 'Select a payment date',
    field: {
      label: "Select the date you're thinking of paying it off",
      supportiveText:
        'You can select any date from today, up to the end of next month',
      error: 'Please enter a valid date',
    },
    confirmButtonLabel: 'Confirm',
    cancelButtonLabel: 'Cancel',
  },
  totalCostDialog: {
    heading: 'Total cost',
    paragraph1a: 'This includes any interest paid up to ',
    paragraph1b:
      ", plus any payments made up to today. It assumes they'll be paid in full.",
    paragraph2:
      'It also only covers your mortgage with us, not any other borrowing you might have secured against your property.',
  },
  ercDialog: {
    heading: 'Early repayment charges',
    paragraph1:
      'ERCs are payable if you repay part or all of your mortgage in full, while ERCs still apply.',
    paragraph2:
      "We work them out as a percentage of the amount you repay. We've included details of the current charges and end dates in the breakdown.",
    paragraph3:
      'It might be best to wait until your ERCs reduce or end before you make a payment.',
    paragraph4:
      'ERCs are worked out and applied on the date we receive your payment.',
  },
  redemptionIntro: "Here's the cost to pay off your mortgage in full on:",
  changeLinkLabel: 'Change',
  totalCostLinkLabel: 'Important info about the total cost',
  breakdownBox: {
    heading: 'How we worked out the total cost',
    balanceLabel: 'Balance on',
    adminChargeLabel: 'Closing administration charge:',
    ercLabel: 'Early repayment charge (ERC):',
  },
  ercBreakdownSectionHeading: 'A breakdown of your ERCs',
  ercBreakdownLinkLabel: 'Important info about ERCs',
  ercSubAccountBreakdown: {
    chargesNotification: {
      paragraph1a: 'If you paid off this sub-account on ',
      paragraph1b: " you'd pay ",
      paragraph1c: ' in ERCs.',
    },
    noChargesNotification: {
      paragraph1: 'There are no ERCs to pay on this sub-account.',
    },
    breakdownIntro:
      'How the percentage we use to work out your ERCs decreases over time:',
    currentPercentLabel: 'At the moment we use a percentage of:',
    upcomingPercentLabel: 'Followed by:',
    ercFreeLabel: "You'll pay no ERCs from:",
  },
  nextStepsSection: {
    heading: 'Your next steps',
    readyToPaySection: {
      heading: 'Ready to pay?',
      paragraph1:
        "You'll find the different ways you can make the payment on the next page.",
    },
    notReadyToPaySection: {
      heading: 'Not quite ready to pay?',
      paragraph1:
        "Note down the different ways to pay on the next page, so you've got them for when you're ready.",
      paragraph2:
        "Or come back on the date you've selected to make the payment.",
      paragraph3a: 'You can also ',
      requestPaperStatementLink: 'request a paper statement',
      paragraph3b:
        " in the post. It'll include the total cost and the different ways to pay. This can take up to five working days to arrive.",
    },
    beforePayingNotification: {
      heading: 'Before you make your payment',
      paragraph1:
        "Please make a note of the total cost, as we won't show this amount after you continue.",
      paragraph2:
        "It's important you make the payment on the date you've selected as the costs change daily.",
      paragraph3:
        'If you pay on a different date, you could pay more in interest or ERCs.',
    },
  },
  howToPayButtonLabel: 'Find out how to pay',
  returnLinkLabel: 'Return to mortgage overview',
};

export default redemption;
