import { AppContent } from '../AppContent';

const makePaymentBankTransfer: AppContent['makePaymentBankTransfer'] = {
  heading: 'Pay by bank transfer',
  beforePaymentSection: {
    heading: 'Before you make your payment, please:',
    list: [
      'make a note of the account details before leaving this page',
      "make sure you're using a current account, not a savings account or credit card",
      "check the payment limits on the account you're paying from.",
    ],
  },
  importantToKnowSection: {
    heading: 'Important to know',
    list: [
      "Don't cancel any Direct Debits - we'll do this for you automatically.",
      "You'll usually receive confirmation that your account's been settled in 15 days.",
    ],
  },
  paymentDetailsSection: {
    heading: "Here's our payment details",
    accountNameLabel: 'Account name:',
    accountName: 'Halifax',
    sortCodeLabel: 'Sort code:',
    sortCode: '30-15-99',
    accountNumberLabel: 'Account number:',
    accountNumber: '00580006',
    paymentReferenceLabel: 'Payment reference:',
    paymentReferenceAlternativeText:
      "Your 14-digit mortgage account number followed by '00'.",
  },
  backButtonLabel: 'Back',
  returnToOverviewLink: 'Return to mortgage overview',
};

export default makePaymentBankTransfer;
