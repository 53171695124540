import { Box } from '@constellation/core';
import styled from 'styled-components';

export const PropertyBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white !important;
`;

export const StyledIconContainer = styled.div`
  span {
    min-width: 2rem;
    min-height: 2rem;
    justify-content: flex-start !important;
    color: ${({ theme }) => theme.color_source_secondary_1} !important;
  }
`;
