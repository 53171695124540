import isPast from './isPast';
import isValidDate from './isValidDate';

const isValidRedemptionDate = (
  day: string,
  month: string,
  year: string,
): boolean => {
  if (!day || !month || !year) {
    return false;
  }

  const parsedDate = parseInt(day, 10);
  const parsedMonth = parseInt(month, 10);
  const parsedYear = parseInt(year, 10);

  if (!isValidDate(parsedDate, parsedMonth, parsedYear)) {
    return false;
  }

  const selectedDate = new Date(parsedYear, parsedMonth - 1, parsedDate);

  if (isPast(selectedDate)) {
    return false;
  }

  const today = new Date();
  const endOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);

  if (selectedDate > endOfNextMonth) {
    return false;
  }

  return true;
};

export default isValidRedemptionDate;
