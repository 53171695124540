import { AppContent } from '../AppContent';

const onceMadePaymentAccordion: AppContent['onceMadePaymentAccordion'] = {
  label: "Once you've made your payment",
  confirmationOfRepaymentSection: {
    heading: 'Confirmation of repayment',
    paragraph1:
      "In most cases, you'll receive confirmation your mortgage has been repaid within 15 days of payment.",
  },
  yourPaymentsSection: {
    heading: 'Your payments',
    paragraph1:
      'Please cancel your standing order with your bank, if you made your monthly payments this way.',
    paragraph2:
      "If you paid your mortgage by Direct Debit, we'll cancel it automatically.",
    paragraph3:
      "If any extra payments have been made, we'll refund you within 15 days. We'll send you a letter to confirm we've done it.",
  },
  insuranceSection: {
    heading: 'Insurance',
    paragraph1:
      'Please review you insurance cover to make sure it still meets your needs.',
  },
  legalChargesEnglandWalesIrelandSection: {
    heading: 'Legal charges - England, Wales and Northern Ireland',
    paragraph1:
      "You don't need to use a conveyancer to repay your mortgage. We'll remove our charge at the Land Registry once your mortgage has been repaid.",
  },
  legalChargesScotlandSection: {
    heading: 'Legal charges - Scotland',
    paragraph1:
      "If you're already using a conveyancer, they'll deal with the repayment and discharge for you.",
    paragraph2:
      "If not, you'll need to ask a solicitor to remove our charge from your property once you've repaid your mortgage.",
  },
  registeredWithLandRegistrySection: {
    heading: "If your property's registered with the Land Registry",
    paragraph1:
      "Your documents are stored electronically on your behalf. You don't need to do anything.",
  },
  notRegisteredWithLandRegistrySection: {
    heading:
      "If we hold your title deeds or your property isn't registered with the Land Registry",
    paragraph1:
      "You'll receive your deeds within 28 days of mortgage repayment.",
  },
};

export default onceMadePaymentAccordion;
