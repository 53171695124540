import React from 'react';

import { Navigate } from '@interstellar/react-app-routing';
import * as routes from 'client/routes/manifest';
import { useAppSelector } from 'client/store';

function ProtectedRoute({ children }) {
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  return isLoggedIn ? children : <Navigate to={routes.SignIn} replace />;
}

export default ProtectedRoute;
