const isValidNonZeroCurrencyAmount = (value: string): boolean => {
  if (!value) {
    return false;
  }

  const strippedValue = value.replace(/[^0-9.]/g, '');

  if (strippedValue === '') {
    return false;
  }

  const parsedValue = parseFloat(strippedValue);

  if (parsedValue === 0) {
    return false;
  }

  return true;
};

export default isValidNonZeroCurrencyAmount;
