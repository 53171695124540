import React, { useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  Heading,
  List,
  ListItem,
  Main,
  Paragraph,
  Spinner,
  TextField,
  Notification,
  Link,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useLocation, useNavigate } from '@interstellar/react-app-routing';
import { ErrorSummaryBuilder } from 'client/components/errorSummaryBuilder';
import debitChangeRequest from 'client/content/_base/debitChangeRequest';
import { useFormMutation } from 'client/services/api';
import {
  getJourneyStep,
  logTealiumBlurEvent,
  logTealiumButtonClickEvent,
  logTealiumNavEvent,
} from 'client/tealium';
import isValidAccountNumber from 'client/validation/isValidAccountNumber';
import isValidPostcode from 'client/validation/isValidPostcode';
import isValidTelephoneNumber from 'client/validation/isValidTelephoneNumber';
import { Formik, Form, Field } from 'formik';

import {
  DebitChangeRequestContent,
  DebitRequestContent,
} from './DebitChangeRequest.config';
import * as routes from '../../manifest';
import RequestForm from '../components/requestForm/RequestForm';
import { RequestFormContent } from '../components/requestForm/RequestForm.config';

interface FormValues {
  title: string;
  firstName: string;
  lastName: string;
  address: string;
  postcode: string;
  accountNumber: string;
  telephone: string;
  newDate: string;
  confirmationCheckbox: boolean;
}

export default function DebitChangeRequestPage() {
  const location = useLocation();

  const {
    pageHeading,
    openingSection,
    whatYouNeedToKnow,
    formInstruction,
    newDateLabel,
    submitButtonText,
  } = useContent<DebitChangeRequestContent>();

  const {
    errorNotificationHeading,
    errorNotificationBody,
    confirmationCheckboxText,
    confirmationCheckboxError,
    validationErrorTitle,
  } = useContent<DebitRequestContent>();

  const requestForm = useContent<RequestFormContent>();

  const [showValidationError, setShowValidationError] =
    useState<boolean>(false);

  const validateForm = (values: FormValues) => {
    const errors: Partial<{
      title: string;
      firstName: string;
      lastName: string;
      address: string;
      postcode: string;
      accountNumber: string;
      telephone: string;
      newDate: string;
      confirmationCheckbox: string;
    }> = {};

    if (!values.title) {
      errors.title = requestForm.errors.title;
    }
    if (!values.firstName) {
      errors.firstName = requestForm.errors.firstName;
    }
    if (!values.lastName) {
      errors.lastName = requestForm.errors.lastName;
    }
    if (!values.accountNumber) {
      errors.accountNumber = requestForm.errors.accountNumber;
    }
    if (!values.address) {
      errors.address = requestForm.errors.address;
    }
    if (!values.postcode) {
      errors.postcode = requestForm.errors.postcode;
    }
    if (!values.telephone) {
      errors.telephone = requestForm.errors.telephone;
    }
    if (!values.newDate) {
      errors.newDate = debitChangeRequest.newDateError;
    }
    if (!values.confirmationCheckbox) {
      errors.confirmationCheckbox = confirmationCheckboxError;
    }

    if (!isValidPostcode(values.postcode)) {
      errors.postcode = requestForm.errors.invalidPostcode;
    }
    if (!isValidAccountNumber(values.accountNumber)) {
      errors.accountNumber = requestForm.errors.invalidAccountNumber;
    }
    if (!isValidTelephoneNumber(values.telephone)) {
      errors.telephone = requestForm.errors.invalidTelephone;
    }

    if (
      values.newDate === '' ||
      Number(values.newDate) < 1 ||
      Number(values.newDate) > 28
    ) {
      errors.newDate = debitChangeRequest.newDateError;
    }

    return errors;
  };

  const numberRegex = /^[0-9]+$/;

  const [form, { isLoading: isFormLoading }] = useFormMutation();

  const navigate = useNavigate();
  const [showSubmissionError, setShowSubmissionError] =
    useState<boolean>(false);
  const hasEmptyFields = (values) =>
    Object.values(values).some((value) => value === '');
  let focusTimerStart: number;

  const handleTimerEnd = (fieldName: string) => {
    logTealiumBlurEvent(fieldName, Date.now() - focusTimerStart);
  };

  useEffect(() => {
    logTealiumNavEvent(
      'Statement request forms',
      getJourneyStep(location),
      undefined,
      'Pre-Application',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Main>
      {isFormLoading && <Spinner />}
      <Link
        iconPosition="left"
        as="button"
        data-testid="back-button-link"
        onClick={() => {
          logTealiumButtonClickEvent('button/back');
          navigate(routes.MortgageOverview);
        }}
      >
        Back
      </Link>
      <Heading as="h1" size="s7" data-testid="debit-change-header">
        {pageHeading}
      </Heading>

      <Formik
        initialValues={{
          title: '',
          firstName: '',
          lastName: '',
          address: '',
          postcode: '',
          accountNumber: '',
          telephone: '',
          newDate: '',
          confirmationCheckbox: false,
        }}
        validate={validateForm}
        onSubmit={async (values: FormValues) => {
          const submitSuccessful = await form({
            personalDetails: { ...values },
          });

          if ('data' in submitSuccessful) {
            navigate(routes.SuccessfulRequest);
          } else {
            setShowSubmissionError(true);
            window.scrollTo({ top: 140, behavior: 'smooth' });
          }
        }}
      >
        {({
          values,
          touched,
          errors,
          isValid,
          handleChange,
          setFieldValue,
          handleBlur,
        }) => (
          <Form noValidate>
            <ErrorSummaryBuilder
              errors={errors}
              isVisible={showValidationError && !isValid}
              errorTitle={validationErrorTitle}
            />
            {showSubmissionError && (
              <Notification
                heading={errorNotificationHeading}
                sentiment="critical"
              >
                <Paragraph>{errorNotificationBody.line1}</Paragraph>
                <Paragraph>{errorNotificationBody.line2}</Paragraph>
                <Paragraph>{errorNotificationBody.line3}</Paragraph>
              </Notification>
            )}

            <Paragraph data-testid="debit-change-opening-section">
              {openingSection.firstParagraph}
            </Paragraph>

            <Heading data-testid="what-you-need-to-know-header">
              {whatYouNeedToKnow.heading}
            </Heading>

            <List data-testid="debit-change-list">
              {whatYouNeedToKnow.listItems.map((item, index) => (
                <ListItem
                  data-testid={`debit-change-list-item-${index + 1}`}
                  key={item}
                >
                  {item}
                </ListItem>
              ))}
            </List>
            <Paragraph data-testid="debit-change-form-instruction">
              {formInstruction}
            </Paragraph>
            <RequestForm
              handleBlur={handleBlur}
              values={values}
              touched={touched}
              errors={errors}
              requestFormFieldLabels={requestForm}
            />
            <Field
              as={TextField}
              data-testid="debit-change-newdate-field"
              name="newDate"
              id="newDate"
              label={newDateLabel}
              autoComplete="off"
              error={touched.newDate && errors.newDate}
              onFocus={() => {
                focusTimerStart = Date.now();
              }}
              onBlur={(e) => {
                handleBlur(e);
                handleTimerEnd('newDate');
              }}
              onChange={(e) => {
                const { value } = e.target;

                if (numberRegex.test(value) || value === '') {
                  handleChange(e);
                  setFieldValue('newDate', value);
                }
              }}
            />
            <Field
              name="confirmationCheckbox"
              as={Checkbox}
              label={confirmationCheckboxText}
              error={
                touched.confirmationCheckbox && errors.confirmationCheckbox
              }
              onClick={() => logTealiumButtonClickEvent('button/confirm')}
              value={values.confirmationCheckbox}
              data-testid="request-form-confirmation-checkbox"
              marginBottom="04"
            />
            <Button
              type="submit"
              data-testid="request-form-submit-button"
              onClick={() => {
                logTealiumButtonClickEvent('button/submit');
                if (!isValid || hasEmptyFields(values)) {
                  setShowValidationError(true);
                  window.scrollTo({ top: 140, behavior: 'smooth' });
                }
              }}
            >
              {submitButtonText}
            </Button>
          </Form>
        )}
      </Formik>
    </Main>
  );
}
