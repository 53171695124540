import styled from 'styled-components';

const transitionHeight = (durationMs: number) =>
  `grid-template-rows ${durationMs}ms linear`;

export const InnerContainer = styled.div`
  overflow: hidden;
`;

export const AnimatedContainer = styled.div<{
  isVisible: boolean;
  durationMs: number;
}>`
  display: grid;
  grid-template-rows: ${({ isVisible }) => (isVisible ? '1fr' : '0fr')};
  transition: ${({ isVisible, durationMs }) =>
    isVisible ? 'none' : transitionHeight(durationMs)};
`;
