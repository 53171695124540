import { AppContent } from '../AppContent';

const overpaymentsRequest: AppContent['overpaymentsRequest'] = {
  pageTitle: 'Request confirmation of overpayments allowance',
  requestInformation: {
    openingParagraph: `If you would like confirmation of your remaining overpayment allowance for this year, please complete this form and you will receive a letter confirming any remaining allowance within 5 working days.`,
    listHeading: 'What you need to know',
    sectionListItems: [
      `We can only deliver to the correspondence address we currently hold on our system.`,
      `Your allowance runs against the calendar year (1st January - 31st December).`,
    ],
    nextActionsInfo: 'Please complete the following information:',
  },
  validationErrorTitle: "Something's not quite right",
  confirmationCheckboxText:
    'I confirm that I am named on this account and would like you to send this information to the correspondence address for the mortgaged property.',
  confirmationCheckboxError: 'Please confirm',
};

export default overpaymentsRequest;
