export const calculateNetYield = (
  propertyPurchasePrice: number,
  monthlyRentalIncome: number,
  annualRunningCosts: number,
): number => {
  const annualRentalIncome = monthlyRentalIncome * 12;
  const annualNetRentalIncome = annualRentalIncome - annualRunningCosts;
  const result = (annualNetRentalIncome / propertyPurchasePrice) * 100;
  return Math.round(result * 100) / 100;
};

const parseAndCalculateNetYield = (
  propertyPurchasePrice: string,
  monthlyRentalIncome: string,
  annualRunningCosts: string,
): number => {
  const parsedPropertyPurchasePrice = parseFloat(
    propertyPurchasePrice.replace(/[£,]/g, ''),
  );
  const parsedMonthlyRentalIncome = parseFloat(
    monthlyRentalIncome.replace(/[£,]/g, ''),
  );
  const parsedAnnualRunningCosts = parseFloat(
    annualRunningCosts.replace(/[£,]/g, ''),
  );

  return calculateNetYield(
    parsedPropertyPurchasePrice,
    parsedMonthlyRentalIncome,
    parsedAnnualRunningCosts,
  );
};

export default parseAndCalculateNetYield;
