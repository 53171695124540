import React from 'react';

import { ContentContainer, StyledIconContainer } from './BoxWithIcon.styled';

interface BoxWithIconProps {
  'data-testid'?: string;
  children: React.ReactNode;
  icon: React.ReactElement;
}

function BoxWithIcon({
  icon,
  children,
  'data-testid': testId,
}: BoxWithIconProps) {
  return (
    <ContentContainer data-testid={testId}>
      <StyledIconContainer>{icon}</StyledIconContainer>
      <div>{children}</div>
    </ContentContainer>
  );
}

export default BoxWithIcon;
