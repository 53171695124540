import React from 'react';

import { Text } from '@constellation/core';
import { ArrowDown, ArrowUp } from '@constellation/core/icons';

import { Capsule } from './DifferenceIndicator.styled';

interface DifferenceIndicatorProps {
  amount: number;
  percentageChange: string;
  'data-testid'?: string;
}

const formatAsCurrencyWithSign = (value: number) =>
  Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    signDisplay: 'always',
    maximumFractionDigits: 0,
  }).format(value);

function DifferenceIndicator({
  amount,
  percentageChange,
  'data-testid': testId,
}: DifferenceIndicatorProps) {
  const positive = amount >= 0;
  const percentChangeLabel = `(${percentageChange}%) since purchase`;
  const dataTestLabel = testId || 'difference-indicator';

  return (
    <Capsule $positive={positive}>
      {positive ? (
        <ArrowUp
          color="success"
          iconSize="md"
          data-testid={`${dataTestLabel}-arrow-up`}
        />
      ) : (
        <ArrowDown
          color="critical"
          iconSize="md"
          data-testid={`${dataTestLabel}-arrow-down`}
        />
      )}
      <Text weight="bold" data-testid={`${dataTestLabel}-value-change-label`}>
        {formatAsCurrencyWithSign(amount)}
      </Text>{' '}
      <Text data-testid={`${dataTestLabel}-percent-change-label`}>
        {percentChangeLabel}
      </Text>
    </Capsule>
  );
}

export default DifferenceIndicator;
