import { useCallback, useEffect, useMemo } from 'react';

import { useNavigate } from '@interstellar/react-app-routing';
import * as routes from 'client/routes/manifest';
import {
  useOverviewMutation,
  useRedemptionMutation,
} from 'client/services/api';
import { Overview } from 'client/services/types/overview';
import {
  ErcBandDetail,
  ErcFeeDetail,
  Redemption,
} from 'client/services/types/redemption';
import { useAppSelector } from 'client/store';

import { MappedSubAccount } from '../components/ErcSubAccountAccordion/ErcSubAccountAccordion';

const UNABLE_TO_SHOW_ERROR_CODES = [
  'E0003',
  'E0004',
  'E0005',
  'E0006',
  '02387',
  '02980',
];

const ALREADY_REQUESTED_ERROR_CODE = '05227';

interface RedemptionDate {
  day: string;
  month: string;
  year: string;
}

export const mapBands = (bands: ErcBandDetail[]) =>
  bands
    .reduce((acc, { ERCPercentage, ERCBandExpiryDate, ERCInd }) => {
      const band = {
        percentage: parseFloat(ERCPercentage),
        expiryDate: new Date(ERCBandExpiryDate),
      };

      if (ERCInd === 'N') {
        return acc;
      }

      return [...acc, band];
    }, [])
    .sort((a, b) => a.expiryDate - b.expiryDate);

export const mapFees = (fees: ErcFeeDetail[]) =>
  fees
    .reduce((acc, { ERCAmount, ERCBandExpiryDate, ERCInd }) => {
      const fee = {
        amount: parseFloat(ERCAmount),
        expiryDate: new Date(ERCBandExpiryDate),
      };

      if (ERCInd === 'N') {
        return acc;
      }

      if (fee.amount === 0) {
        return acc;
      }

      return [...acc, fee];
    }, [])
    .sort((a, b) => a.expiryDate - b.expiryDate);

export const mapSubAccounts = (
  redemptionData: Redemption,
  overview: Overview,
): MappedSubAccount[] => {
  const { ercBandDetails, ercFeeDetails } = redemptionData;

  const subAccountNumbers = new Set(
    [...ercBandDetails, ...ercFeeDetails].map(
      ({ subAccountNumber }) => subAccountNumber,
    ),
  );

  const mappedSubAccounts = Array.from(subAccountNumbers).map(
    (subAccountNumber) => {
      const rawBands = ercBandDetails.filter(
        (band) => band.subAccountNumber === subAccountNumber,
      );

      const bands = mapBands(rawBands);

      const rawFees = ercFeeDetails.filter(
        (fee) => fee.subAccountNumber === subAccountNumber,
      );

      const fees = mapFees(rawFees);

      const overviewDataForSubAccount = overview.overview.subAccounts.find(
        (subAccount) =>
          subAccount.subAccountNumber.padStart(2, '0') === subAccountNumber,
      );

      const { interestRate, productEndDate } = overviewDataForSubAccount || {};

      return {
        subAccountNumber,
        interestRate,
        productEndDate,
        bands,
        fees,
      };
    },
  );

  return mappedSubAccounts.sort(
    (a, b) =>
      parseInt(a.subAccountNumber, 10) - parseInt(b.subAccountNumber, 10),
  );
};

const useRedemption = (redemptionDate: RedemptionDate) => {
  const [
    overview,
    {
      isLoading: overviewIsLoading,
      isError: overviewIsError,
      data: overviewData,
    },
  ] = useOverviewMutation();
  const [
    redemption,
    {
      isLoading: redemptionIsLoading,
      isError: redemptionIsError,
      data: redemptionData,
    },
  ] = useRedemptionMutation();
  const overviewState = useAppSelector<Overview>(
    (appState) => appState.overview,
  );
  const navigate = useNavigate();

  const makeRedemptionRequest = useCallback(
    async (
      requestedRedemptionDate: RedemptionDate,
      requestType: 'E' | 'R' | 'S',
      onSuccess?: () => void,
    ) => {
      const requestRedemptionDate = `${requestedRedemptionDate.year}-${requestedRedemptionDate.month.padStart(2, '0')}-${requestedRedemptionDate.day.padStart(2, '0')}`;

      const response = await redemption({
        redemptionDate: requestRedemptionDate,
        requestType,
      });

      if (!response || !response.data) {
        navigate(routes.RedemptionError);
        return;
      }

      if ('errors' in response.data && response.data.errors.length > 0) {
        const unableToShowFigures = response.data.errors.some(
          ({ reasonCode }) => UNABLE_TO_SHOW_ERROR_CODES.includes(reasonCode),
        );

        if (unableToShowFigures) {
          navigate(routes.RedemptionUnavailable);
          return;
        }

        const alreadyRequested = response.data.errors.some(
          (error) => error.reasonCode === ALREADY_REQUESTED_ERROR_CODE,
        );

        if (alreadyRequested) {
          navigate(routes.RedemptionAlreadyRequested);
          return;
        }

        navigate(routes.RedemptionError);
        return;
      }

      if (onSuccess) {
        onSuccess();
      }
    },
    [navigate, redemption],
  );

  const requestPaperStatement = useCallback(
    async (requestedRedemptionDate: {
      day: string;
      month: string;
      year: string;
    }) => {
      await makeRedemptionRequest(requestedRedemptionDate, 'S', () => {
        navigate(routes.RedemptionSuccess);
      });
    },
    [makeRedemptionRequest, navigate],
  );

  // Refetch overview on refresh if we don't have the overview data anymore
  useEffect(() => {
    const refetchOverview = async () => {
      await overview();
    };

    if (!overviewData && !overviewState.overview) {
      refetchOverview();
    }
  }, [overview, overviewData, overviewState]);

  // Get redemption values when the redemption date updates.
  useEffect(() => {
    const getRedemptionValues = async () => {
      await makeRedemptionRequest(redemptionDate, 'R');
    };

    getRedemptionValues();
  }, [makeRedemptionRequest, redemptionDate]);

  // Map sub accounts from redemption into format needed for the UI
  const subAccounts = useMemo(() => {
    if (!redemptionData) return [];
    if (!overviewState.overview) return [];
    return mapSubAccounts(redemptionData, overviewState);
  }, [redemptionData, overviewState]);

  const {
    redemptionAmount,
    balanceAmount,
    closingAdminCharge,
    mortgageERCAmount,
  } = redemptionData || {};

  return {
    requestPaperStatement,
    isLoading: redemptionIsLoading || overviewIsLoading,
    isError: redemptionIsError || overviewIsError,
    subAccounts,
    redemptionAmount,
    balanceAmount,
    closingAdminCharge,
    mortgageERCAmount,
  };
};

export default useRedemption;
