import styled from 'styled-components';

export const NotificationBox = styled.div`
  border-color: ${({ theme }) => theme.color_border_information};
  border-radius: 8px;
  border-style: solid;
  border-width: 2px;
  padding: 12px;
  background-color: ${({ theme }) => theme.color_border_inverse_default_1};
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: ${({ theme }) => theme.spacing_06};
`;

export const NotificationBoxItem = styled.div`
  margin-left: 0rem;
  margin-right: 0rem;

  @media (min-width: ${({ theme }) => theme.breakpoint_md}) {
    margin-left: ${({ theme }) => theme.spacing_03};
    margin-right: ${({ theme }) => theme.spacing_03};
  }
`;
