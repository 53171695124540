const formatDate = (date: string) => {
  const parsedDate = new Date(date);

  const formattedDay = parsedDate.getDate().toString().padStart(2, '0');
  const formattedMonth = (parsedDate.getMonth() + 1)
    .toString()
    .padStart(2, '0');
  const formattedYear = parsedDate.getFullYear().toString().slice(-2);

  return `${formattedDay}/${formattedMonth}/${formattedYear}`;
};

export default formatDate;
