import React from 'react';

import { IconChevronRight, Text } from '@constellation/core';

import { StyledLink } from './FullWidthLink.styled';

interface FullWidthLinkProps {
  children?: any;
  to: string;
  'data-testid'?: string;
  onClick?: React.MouseEventHandler;
}

function FullWidthLink({
  children,
  to,
  'data-testid': testId,
  onClick,
}: FullWidthLinkProps) {
  return (
    <StyledLink
      to={to}
      onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        onClick(e);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
      data-testid={testId}
    >
      <Text color="inherit">{children}</Text>
      <IconChevronRight iconSize="md" />
    </StyledLink>
  );
}

export default FullWidthLink;
