import styled from 'styled-components';

export const SideBySideContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing_05};
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.color_border_default_2};
`;
export const RightContainer = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  align-items: center;
`;
