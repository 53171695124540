import React from 'react';

import { Link, IconConversation, Paragraph } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { BoxWithIcon } from 'client/components/boxWithIcon';
import { Overview } from 'client/services/types/overview';
import { useAppSelector } from 'client/store';
import { logTealiumButtonClickEvent } from 'client/tealium';

import { MortgageOverviewPageContent } from '../../MortgageOverviewPage.config';

function FeedbackPanel() {
  const { feedbackPanel } = useContent<MortgageOverviewPageContent>();
  const state = useAppSelector<Overview>((appState) => appState.overview);

  const { overview } = state || {};
  const { mortgageAccountNumber } = overview || {
    mortgageAccountNumber: '',
  };

  const feedbackUrl = mortgageAccountNumber
    ? `${feedbackPanel.link.url}?id=${mortgageAccountNumber}`
    : feedbackPanel.link.url;

  return (
    <BoxWithIcon
      icon={<IconConversation iconSize="lg" trim />}
      data-testid="overview-feedback-panel"
    >
      <Paragraph marginBottom="01">{feedbackPanel.text}</Paragraph>
      <Link
        href={feedbackUrl}
        external
        name="feedback-link"
        data-testid="overview-feedback-link"
        onClick={() => logTealiumButtonClickEvent('button/giveusyourfeedback')}
      >
        {feedbackPanel.link.text}
      </Link>
    </BoxWithIcon>
  );
}

export default FeedbackPanel;
