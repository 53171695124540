import React, { ReactElement } from 'react';

import { ContentProvider } from '@interstellar/react-app-content';
import { Routes, Route } from '@interstellar/react-app-routing';
import ProtectedRoute from 'client/components/protectedRoute/ProtectedRoute';

import BorrowMorePage from './borrowMore/BorrowMorePage';
import ErrorPage from './errorPage/ErrorPage';
import MakePaymentBankTransferPage from './makePayment/bankTransfer/MakePaymentBankTransferPage';
import MakePaymentPage from './makePayment/MakePaymentPage';
import MakePaymentPhonePage from './makePayment/phone/MakePaymentPhonePage';
import * as routes from './manifest';
import MortgageOverviewPage from './mortgageOverview/MortgageOverviewPage';
import NotFoundPage from './notFound/NotFoundPage';
import OverpaymentsPage from './overpayments/OverpaymentsPage';
import ProductTransferPage from './productTransfer/ProductTransferPage';
import RedemptionAlreadyRequestedPage from './redemption/alreadyRequested/RedemptionAlreadyRequestedPage';
import RedemptionErrorPage from './redemption/error/RedemptionErrorPage';
import RedemptionPage from './redemption/RedemptionPage';
import RedemptionSuccessPage from './redemption/success/RedemptionSuccessPage';
import RedemptionUnavailablePage from './redemption/unavailable/RedemptionUnavailablePage';
import RentalYieldCalculatorPage from './rentalYieldCalculator/RentalYieldCalculatorPage';
import DebitChangeRequestPage from './requests/debitDateChange/DebitChangeRequestPage';
import DuplicateStatementPage from './requests/duplicateStatement/DuplicateStatementPage';
import MortgageInterestPage from './requests/mortgageInterest/MortgageInterestPage';
import OverpaymentsRequestPage from './requests/overpayments/OverpaymentsRequestPage';
import RedemptionStatementPage from './requests/redemptionStatement/RedemptionStatementPage';
import SuccessfulRequestPage from './requests/successfulRequest/SuccessfulRequestPage';
import ServiceUnavailablePage from './serviceUnavailable/ServiceUnavailablePage';
import SignInPage from './signIn/SignInPage';
import SignOutPage from './signOut/SignOutPage';
import useAppConfig from '../components/appConfig/useAppConfig';
import content from '../content';
import { getThemeFromUrl } from '../utils/getThemeFromUrl';

function App(): ReactElement {
  const brandConfig = useAppConfig().BRAND_NAME;
  const brand = getThemeFromUrl() || brandConfig;
  const { requestFormsEnabled } = window.appConfig;

  return (
    <Routes>
      <Route
        path={routes.SignIn}
        element={
          <ContentProvider value={content({ brand }).signIn}>
            <SignInPage />
          </ContentProvider>
        }
      />

      <Route
        path={routes.SignedOut}
        element={
          <ContentProvider value={content({ brand }).signOut}>
            <SignOutPage />
          </ContentProvider>
        }
      />

      <Route
        path={routes.MortgageOverview}
        element={
          <ProtectedRoute>
            <ContentProvider
              value={{
                ...content({ brand }).mortgageOverview,
                ...content({ brand }).signOutModal,
              }}
            >
              <MortgageOverviewPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.RentalYield}
        element={
          <ProtectedRoute>
            <ContentProvider value={content({ brand }).rentalYieldCalculator}>
              <RentalYieldCalculatorPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.ProductTransfer}
        element={
          <ProtectedRoute>
            <ContentProvider value={content({ brand }).productTransfer}>
              <ProductTransferPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.BorrowMore}
        element={
          <ProtectedRoute>
            <ContentProvider value={content({ brand }).borrowMore}>
              <BorrowMorePage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.Overpayments}
        element={
          <ProtectedRoute>
            <ContentProvider value={content({ brand }).overpayments}>
              <OverpaymentsPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      {requestFormsEnabled && (
        <Route
          path={routes.RequestOverpayments}
          element={
            <ProtectedRoute>
              <ContentProvider
                value={{
                  ...content({ brand }).overpaymentsRequest,
                  ...content({ brand }).requestForm,
                }}
              >
                <OverpaymentsRequestPage />
              </ContentProvider>
            </ProtectedRoute>
          }
        />
      )}

      {requestFormsEnabled && (
        <Route
          path={routes.RedemptionStatement}
          element={
            <ProtectedRoute>
              <ContentProvider
                value={{
                  ...content({ brand }).redemptionStatement,
                  ...content({ brand }).requestForm,
                }}
              >
                <RedemptionStatementPage />
              </ContentProvider>
            </ProtectedRoute>
          }
        />
      )}

      {requestFormsEnabled && (
        <Route
          path={routes.DuplicateStatement}
          element={
            <ProtectedRoute>
              <ContentProvider
                value={{
                  ...content({ brand }).duplicateStatement,
                  ...content({ brand }).requestForm,
                }}
              >
                <DuplicateStatementPage />
              </ContentProvider>
            </ProtectedRoute>
          }
        />
      )}

      {requestFormsEnabled && (
        <Route
          path={routes.DebitChangeRequest}
          element={
            <ProtectedRoute>
              <ContentProvider
                value={{
                  ...content({ brand }).debitChangeRequest,
                  ...content({ brand }).requestForm,
                }}
              >
                <DebitChangeRequestPage />
              </ContentProvider>
            </ProtectedRoute>
          }
        />
      )}

      {requestFormsEnabled && (
        <Route
          path={routes.MortgageInterest}
          element={
            <ProtectedRoute>
              <ContentProvider
                value={{
                  ...content({ brand }).mortgageInterest,
                  ...content({ brand }).requestForm,
                }}
              >
                <MortgageInterestPage />
              </ContentProvider>
            </ProtectedRoute>
          }
        />
      )}

      {requestFormsEnabled && (
        <Route
          path={routes.SuccessfulRequest}
          element={
            <ProtectedRoute>
              <ContentProvider value={content({ brand }).successfulRequest}>
                <SuccessfulRequestPage />
              </ContentProvider>
            </ProtectedRoute>
          }
        />
      )}

      <Route
        path={routes.MakePayment}
        element={
          <ProtectedRoute>
            <ContentProvider value={content({ brand }).makePayment}>
              <MakePaymentPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.MakePaymentBankTransfer}
        element={
          <ProtectedRoute>
            <ContentProvider
              value={{
                ...content({ brand }).makePaymentBankTransfer,
                ...content({ brand }).onceMadePaymentAccordion,
              }}
            >
              <MakePaymentBankTransferPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.MakePaymentPhone}
        element={
          <ProtectedRoute>
            <ContentProvider
              value={{
                ...content({ brand }).makePaymentPhone,
                ...content({ brand }).onceMadePaymentAccordion,
              }}
            >
              <MakePaymentPhonePage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.Redemption}
        element={
          <ProtectedRoute>
            <ContentProvider
              value={{
                ...content({ brand }).redemption,
                ...content({ brand }).onceMadePaymentAccordion,
              }}
            >
              <RedemptionPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.RedemptionError}
        element={
          <ProtectedRoute>
            <ContentProvider value={content({ brand }).redemptionError}>
              <RedemptionErrorPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.RedemptionAlreadyRequested}
        element={
          <ProtectedRoute>
            <ContentProvider
              value={content({ brand }).redemptionAlreadyRequested}
            >
              <RedemptionAlreadyRequestedPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.RedemptionUnavailable}
        element={
          <ProtectedRoute>
            <ContentProvider value={content({ brand }).redemptionUnavailable}>
              <RedemptionUnavailablePage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.RedemptionSuccess}
        element={
          <ProtectedRoute>
            <ContentProvider value={content({ brand }).redemptionSuccess}>
              <RedemptionSuccessPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.Error}
        element={
          <ContentProvider value={content({ brand }).error}>
            <ErrorPage />
          </ContentProvider>
        }
      />

      <Route
        path={routes.ServiceUnavailable}
        element={
          <ContentProvider value={content({ brand }).serviceUnavailable}>
            <ServiceUnavailablePage />
          </ContentProvider>
        }
      />

      <Route
        path="*"
        element={
          <ContentProvider value={content({ brand }).notfound}>
            <NotFoundPage />
          </ContentProvider>
        }
      />
    </Routes>
  );
}

export default App;
