const isValidTelephoneNumber = (telephoneNumber: string) => {
  if (
    !telephoneNumber ||
    telephoneNumber.length < 9 ||
    telephoneNumber.length > 13
  )
    return false;

  const regExp = /^[0-9]+$/;

  if (!regExp.test(telephoneNumber)) {
    return false;
  }

  return true;
};

export default isValidTelephoneNumber;
