import { AppContent } from 'content/AppContent';

const sessionManager: AppContent['sessionManager'] = {
  nudgeModal: {
    title: "You've been away for 15 minutes",
    bodyTextPara1: 'Do you want to continue?',
    bodyTextPara2:
      "We'll close this session in 5 minutes, to keep your account safe.",
    signOutButtonLabel: 'Sign out',
    staySignedInButtonLabel: 'Keep me signed in',
  },
  expiryModal: {
    title: 'Your session has timed out',
    bodyText: "We've signed you out to keep your account safe.",
    signBackInButtonLabel: 'Sign back in',
  },
};

export default sessionManager;
