import { appLayout } from './appLayout';
import borrowMore from './borrowMore';
import debitChangeRequest from './debitChangeRequest';
import duplicateStatement from './duplicateStatement';
import error from './error';
import makePayment from './makePayment';
import makePaymentBankTransfer from './makePaymentBankTransfer';
import makePaymentPhone from './makePaymentPhone';
import mortgageInterest from './mortgageInterest';
import mortgageOverview from './mortgageOverview';
import notfound from './notFound';
import onceMadePaymentAccordion from './onceMadePaymentAccordion';
import overpayments from './overpayments';
import overpaymentsRequest from './overpaymentsRequest';
import productTransfer from './productTransfer';
import redemption from './redemption';
import redemptionAlreadyRequested from './redemptionAlreadyRequested';
import redemptionError from './redemptionError';
import redemptionStatement from './redemptionStatement';
import redemptionSuccess from './redemptionSuccess';
import redemptionUnavailable from './redemptionUnavailable';
import rentalYieldCalculator from './rentalYieldCalculator';
import requestForm from './requestForm';
import serviceUnavailable from './serviceUnavailable';
import sessionManager from './sessionManager';
import signIn from './signIn';
import signOut from './signOut';
import signOutModal from './signOutModal';
import successfulRequest from './successfulRequest';
import versionMismatchDialog from './versionMismatchDialog';
import { AppContent } from '../AppContent';

const content: AppContent = {
  notfound,
  signIn,
  mortgageOverview,
  appLayout,
  signOutModal,
  signOut,
  sessionManager,
  overpayments,
  productTransfer,
  borrowMore,
  error,
  requestForm,
  overpaymentsRequest,
  duplicateStatement,
  successfulRequest,
  redemptionStatement,
  serviceUnavailable,
  mortgageInterest,
  debitChangeRequest,
  makePayment,
  makePaymentBankTransfer,
  makePaymentPhone,
  versionMismatchDialog,
  redemption,
  redemptionAlreadyRequested,
  redemptionError,
  redemptionSuccess,
  redemptionUnavailable,
  onceMadePaymentAccordion,
  rentalYieldCalculator,
};

export default content;
