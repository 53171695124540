import React, { useEffect, useState } from 'react';

import {
  Heading,
  Main,
  Checkbox,
  Button,
  List,
  ListItem,
  Paragraph,
  Notification,
  Dropdown,
  Option,
  Link,
  Spinner,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useLocation, useNavigate } from '@interstellar/react-app-routing';
import { useFormMutation } from 'client/services/api';
import {
  getJourneyStep,
  logTealiumButtonClickEvent,
  logTealiumNavEvent,
} from 'client/tealium';
import isValidAccountNumber from 'client/validation/isValidAccountNumber';
import isValidPostcode from 'client/validation/isValidPostcode';
import isValidTelephoneNumber from 'client/validation/isValidTelephoneNumber';
import { Field, Form, Formik } from 'formik';

import { MortgageInterestContent } from './MortgageInterest.config';
import ErrorSummaryBuilder from '../../../components/errorSummaryBuilder/ErrorSummaryBuilder';
import * as routes from '../../manifest';
import RequestForm from '../components/requestForm/RequestForm';
import { RequestFormContent } from '../components/requestForm/RequestForm.config';

interface FormValues {
  title: string;
  firstName: string;
  lastName: string;
  address: string;
  postcode: string;
  accountNumber: string;
  telephone: string;
  confirmationCheckbox: boolean;
  sentAnnually: string;
}

export default function MortgageInterestPage() {
  const location = useLocation();

  useEffect(() => {
    logTealiumNavEvent(
      'Statement request forms',
      getJourneyStep(location),
      undefined,
      'Pre-Application',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [form, { isLoading: isFormLoading }] = useFormMutation();

  const navigate = useNavigate();
  const [showSubmissionError, setShowSubmissionError] =
    useState<boolean>(false);
  const [showValidationError, setShowValidationError] =
    useState<boolean>(false);

  const hasEmptyFields = (values) =>
    Object.values(values).some((value) => value === '');

  const {
    pageHeading,
    openingSection,
    whatYouNeedToKnowSection,
    formInstruction,
    sentAnnuallyText,
    confirmationCheckboxSection,
    formErrors,
    validationErrorTitle,
    errorNotificationHeading,
    errorNotificationBody,
  } = useContent<MortgageInterestContent>();

  const requestForm = useContent<RequestFormContent>();

  const validateForm = (values: FormValues) => {
    const errors: Partial<{
      title: string;
      firstName: string;
      lastName: string;
      address: string;
      postcode: string;
      accountNumber: string;
      telephone: string;
      confirmationCheckbox: string;
      sentAnnually: string;
    }> = {};

    if (!values.title) {
      errors.title = requestForm.errors.title;
    }
    if (!values.firstName) {
      errors.firstName = requestForm.errors.firstName;
    }
    if (!values.lastName) {
      errors.lastName = requestForm.errors.lastName;
    }
    if (!values.accountNumber) {
      errors.accountNumber = requestForm.errors.accountNumber;
    }
    if (!values.address) {
      errors.address = requestForm.errors.address;
    }
    if (!values.postcode) {
      errors.postcode = requestForm.errors.postcode;
    }
    if (!values.telephone) {
      errors.telephone = requestForm.errors.telephone;
    }
    if (!values.sentAnnually) {
      errors.sentAnnually = formErrors.sentAnnuallyError;
    }
    if (!values.confirmationCheckbox) {
      errors.confirmationCheckbox =
        confirmationCheckboxSection.confirmationCheckboxError;
    }

    if (values.postcode && !isValidPostcode(values.postcode)) {
      errors.postcode = requestForm.errors.invalidPostcode;
    }
    if (values.accountNumber && !isValidAccountNumber(values.accountNumber)) {
      errors.accountNumber = requestForm.errors.invalidAccountNumber;
    }
    if (values.telephone && !isValidTelephoneNumber(values.telephone)) {
      errors.telephone = requestForm.errors.invalidTelephone;
    }

    return errors;
  };

  return (
    <Main>
      {isFormLoading && <Spinner />}
      <Link
        iconPosition="left"
        as="button"
        data-testid="back-button-link"
        onClick={() => {
          logTealiumButtonClickEvent('button/back');
          navigate(routes.MortgageOverview);
        }}
      >
        Back
      </Link>

      <Formik
        initialValues={{
          title: '',
          firstName: '',
          lastName: '',
          address: '',
          postcode: '',
          accountNumber: '',
          telephone: '',
          sentAnnually: '',
          confirmationCheckbox: false,
        }}
        validate={validateForm}
        onSubmit={async (values: FormValues) => {
          const submitSuccessful = await form({
            personalDetails: { ...values },
          });

          if ('data' in submitSuccessful) {
            navigate(routes.SuccessfulRequest);
          } else {
            setShowSubmissionError(true);
            window.scrollTo({ top: 140, behavior: 'smooth' });
          }
        }}
      >
        {({ values, touched, errors, isValid, handleBlur }) => (
          <Form noValidate>
            <ErrorSummaryBuilder
              errors={errors}
              isVisible={showValidationError && !isValid}
              errorTitle={validationErrorTitle}
            />

            {showSubmissionError && (
              <Notification
                heading={errorNotificationHeading}
                sentiment="critical"
                data-testid="overpayments-allowance-submission-error-notification"
              >
                <Paragraph>{errorNotificationBody.line1}</Paragraph>
                <Paragraph>{errorNotificationBody.line2}</Paragraph>
                <Paragraph>{errorNotificationBody.line3}</Paragraph>
              </Notification>
            )}
            <Heading
              data-testid="page-heading"
              as="h1"
              size="s7"
              marginTop="05"
            >
              {pageHeading}
            </Heading>

            <Paragraph data-testid="opening-section-first-paragraph">
              {openingSection.firstParagraph}
            </Paragraph>

            <Heading data-testid="what-you-need-to-know-section-heading">
              {whatYouNeedToKnowSection.heading}
            </Heading>
            <List data-testid="what-you-need-to-know-list">
              {whatYouNeedToKnowSection.listItems.map((item, index) => (
                <ListItem
                  data-testid={`what-you-need-to-know-list-item-${index + 1}`}
                  key={item}
                >
                  {item}
                </ListItem>
              ))}
            </List>

            <Paragraph data-testid="form-instruction">
              {formInstruction}
            </Paragraph>
            <RequestForm
              handleBlur={handleBlur}
              values={values}
              touched={touched}
              errors={errors}
              requestFormFieldLabels={requestForm}
              data-testid="request-form"
            />

            <Field name="sentAnnually" id="sentAnnually">
              {({ field }) => (
                <Dropdown
                  label={sentAnnuallyText}
                  name="sentAnnually"
                  data-testid="request-form-sent-annually"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  error={touched.sentAnnually && errors.sentAnnually}
                  onClick={() => {
                    logTealiumButtonClickEvent('annual');
                  }}
                >
                  <Option hidden>Please select</Option>
                  <Option>Yes</Option>
                  <Option>No</Option>
                </Dropdown>
              )}
            </Field>

            <Field
              as={Checkbox}
              name="confirmationCheckbox"
              label={confirmationCheckboxSection.confirmationMessage}
              error={errors.confirmationCheckbox}
              data-testid="request-form-confirmation-checkbox"
              value={values.confirmationCheckbox}
              onClick={() => {
                logTealiumButtonClickEvent('button/confirm');
              }}
            />

            <Button
              type="submit"
              data-testid="request-form-submit-button"
              onClick={() => {
                logTealiumButtonClickEvent('button/submit');
                if (!isValid || hasEmptyFields(values)) {
                  setShowValidationError(true);
                  window.scrollTo({ top: 140, behavior: 'smooth' });
                }
              }}
            >
              {confirmationCheckboxSection.confirmationCheckboxText}
            </Button>
          </Form>
        )}
      </Formik>
    </Main>
  );
}
