import React, { ReactElement } from 'react';

import { FooterItem, Footer, Link } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { AppLayoutContent } from 'client/components/appLayout/AppLayoutContent.config';

export default function FooterComponent(): ReactElement {
  const {
    footer: { links },
  } = useContent<AppLayoutContent>();

  const footerLinks = [
    { link: links.cookies, id: 'footer-link-cookie-policy' },
    { link: links.accessibility, id: 'footer-link-accessibility-statement' },
    { link: links.termsAndConditions, id: 'footer-link-terms-and-conditions' },
    { link: links.privacy, id: 'footer-link-privacy-notice' },
  ];

  return (
    <Footer>
      {footerLinks.map(({ link: { text, url }, id }) => (
        <FooterItem key={id}>
          <Link data-testid={id} href={url} target="_blank">
            {text}
          </Link>
        </FooterItem>
      ))}
    </Footer>
  );
}
