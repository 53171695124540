import React from 'react';

import { Main, Heading, Link } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';

import { SuccessfulRequestContent } from './SuccessfulRequestPage.config';
import * as routes from '../../manifest';

export default function SuccessfulRequestPage() {
  const navigate = useNavigate();

  const { pageTitle } = useContent<SuccessfulRequestContent>();

  return (
    <Main>
      <Link
        iconPosition="left"
        as="button"
        data-testid="back-button-link"
        onClick={() => navigate(routes.MortgageOverview)}
      >
        Back
      </Link>{' '}
      <Heading
        as="h1"
        size="s7"
        data-testid="overpayments-allowance-page-heading"
        marginTop="03"
      >
        {pageTitle}
      </Heading>
    </Main>
  );
}
