import React from 'react';

import { Box, TextField } from '@constellation/core';
import { logTealiumBlurEvent } from 'client/tealium';
import { Field, FormikErrors, FormikTouched, FormikValues } from 'formik';

import { RequestFormContent } from './RequestForm.config';

interface RequestFormProps {
  values: FormikValues;
  touched: FormikTouched<FormikValues>;
  errors: FormikErrors<FormikValues>;
  requestFormFieldLabels: RequestFormContent;
  handleBlur: (e: React.FocusEvent<any>) => void;
}

export default function RequestForm({
  values,
  touched,
  errors,
  requestFormFieldLabels,
  handleBlur,
}: RequestFormProps) {
  let focusTimerStart: number;

  const handleTimerEnd = (fieldName: string) => {
    logTealiumBlurEvent(fieldName, Date.now() - focusTimerStart);
  };

  return (
    <Box bgColor="neutral2" padding="none" data-testid="request-form">
      <Field
        name="title"
        as={TextField}
        label={requestFormFieldLabels.titleFieldLabel}
        value={values.title}
        error={touched.title && errors.title}
        data-testid="request-form-title"
        marginBottom="04"
        onFocus={() => {
          focusTimerStart = Date.now();
        }}
        onBlur={(e) => {
          handleBlur(e);
          handleTimerEnd('title');
        }}
      />
      <Field
        name="firstName"
        as={TextField}
        label={requestFormFieldLabels.firstNameFieldLabel}
        error={touched.firstName && errors.firstName}
        value={values.firstName}
        data-testid="request-form-first-name"
        marginBottom="04"
        onFocus={() => {
          focusTimerStart = Date.now();
        }}
        onBlur={(e) => {
          handleBlur(e);
          handleTimerEnd('first name');
        }}
      />
      <Field
        name="lastName"
        as={TextField}
        label={requestFormFieldLabels.lastNameFieldLabel}
        error={touched.lastName && errors.lastName}
        value={values.lastName}
        data-testid="request-form-last-name"
        marginBottom="04"
        onFocus={() => {
          focusTimerStart = Date.now();
        }}
        onBlur={(e) => {
          handleBlur(e);
          handleTimerEnd('last name');
        }}
      />
      <Field
        name="accountNumber"
        as={TextField}
        maxLength={14}
        label={requestFormFieldLabels.accountNumberFieldLabel}
        error={touched.accountNumber && errors.accountNumber}
        value={values.accountNumber}
        data-testid="request-form-account-number"
        marginBottom="04"
        onFocus={() => {
          focusTimerStart = Date.now();
        }}
        onBlur={(e) => {
          handleBlur(e);
          handleTimerEnd('mortgage account number');
        }}
      />
      <Field
        name="address"
        as={TextField}
        label={requestFormFieldLabels.addressFieldLabel}
        error={touched.address && errors.address}
        value={values.address}
        data-testid="request-form-address"
        marginBottom="04"
        onFocus={() => {
          focusTimerStart = Date.now();
        }}
        onBlur={(e) => {
          handleBlur(e);
          handleTimerEnd('property address');
        }}
      />
      <Field
        name="postcode"
        as={TextField}
        label={requestFormFieldLabels.postcodeFieldLabel}
        error={touched.postcode && errors.postcode}
        value={values.postcode}
        data-testid="request-form-postcode"
        marginBottom="04"
        onFocus={() => {
          focusTimerStart = Date.now();
        }}
        onBlur={(e) => {
          handleBlur(e);
          handleTimerEnd('post code');
        }}
      />
      <Field
        name="telephone"
        as={TextField}
        label={requestFormFieldLabels.telephoneFieldLabel}
        error={touched.telephone && errors.telephone}
        value={values.telephone}
        data-testid="request-form-telephone-number"
        marginBottom="04"
        onFocus={() => {
          focusTimerStart = Date.now();
        }}
        onBlur={(e) => {
          handleBlur(e);
          handleTimerEnd('telephone number');
        }}
      />
    </Box>
  );
}
