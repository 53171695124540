import { AppContent } from '../AppContent';

const redemptionSuccess: AppContent['redemptionSuccess'] = {
  heading: "We've got your request",
  paragraph1:
    "Please allow 5 working days for the 'Amount owing' statement to arrive in the post.",
  paragraph2:
    "It'll include the total cost and different ways you can make the payment",
  returnToOverviewLink: 'Return to mortgage overview',
};

export default redemptionSuccess;
