export const calculateGrossYield = (
  propertyPurchasePrice: number,
  monthlyRentalIncome: number,
): number => {
  const annualRentalIncome = monthlyRentalIncome * 12;
  const result = (annualRentalIncome / propertyPurchasePrice) * 100;
  return Math.round(result * 100) / 100;
};

const parseAndCalculateGrossYield = (
  propertyPurchasePrice: string,
  monthlyRentalIncome: string,
): number => {
  const parsedPropertyPurchasePrice = parseFloat(
    propertyPurchasePrice.replace(/[£,]/g, ''),
  );
  const parsedMonthlyRentalIncome = parseFloat(
    monthlyRentalIncome.replace(/[£,]/g, ''),
  );

  return calculateGrossYield(
    parsedPropertyPurchasePrice,
    parsedMonthlyRentalIncome,
  );
};

export default parseAndCalculateGrossYield;
