import React, { useEffect } from 'react';

import {
  Box,
  Grid,
  GridItem,
  Heading,
  Hr,
  Main,
  Notification,
  Paragraph,
  Strong,
  Link
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useLocation, useNavigate } from '@interstellar/react-app-routing';
import { getJourneyStep, logTealiumButtonClickEvent, logTealiumNavEvent } from 'client/tealium';

import { ErrorPageContent } from './ErrorPage.config';

function ErrorPage() {
  const location = useLocation();
  const content = useContent<ErrorPageContent>();
  const { title, box, applicationHours } = content;
  const navigate = useNavigate();

  useEffect(() => {
    logTealiumNavEvent(
      'Error Page',
      location?.state?.previousPath
        ? getJourneyStep({ pathname: location.state.previousPath })
        : '1',
      undefined,
      'Pre-Application',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Main>
      <Heading as="h1" size="s7">
        {title}
      </Heading>

      <Grid>
        <GridItem md={8}>
          <Hr marginTop="none" />

          <Notification
            heading={box.title}
            headingLevel={2}
            sentiment="critical"
          >
            <Paragraph>{box.para1}</Paragraph>
            <Paragraph>
              {box.para2}
              <Strong>{box.para2Strong}</Strong>
            </Paragraph>
            <Paragraph>{box.para3}</Paragraph>
          </Notification>
        </GridItem>

        <GridItem md={4}>
          <Box>
            <Heading size="s4">{applicationHours.title}</Heading>
            <Paragraph>{applicationHours.mainText}</Paragraph>
            <Paragraph>{applicationHours.monToSatHours}</Paragraph>
            <Paragraph marginBottom="none">
              {applicationHours.sunHours}
            </Paragraph>
          </Box>
        </GridItem>
      </Grid>
      <Link
        as='button'
        iconPosition='left'
        data-testid='navigate-back-button'
        onClick={() => {
          navigate(-1);
          logTealiumButtonClickEvent('button/back');
        }}>{content.backButtonLabel}</Link>
    </Main>
  );
}

export default ErrorPage;
