import React from 'react';

import {
  IconPercent,
  Paragraph,
  Strong,
  IconPositive,
  Box,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import { RedemptionContent } from '../../RedemptionPage.config';
import {
  ErcBreakdownItem,
  ErcBreakdownItemDescription,
  ErcBreakdownItemIcon,
  ErcBreakdownList,
} from '../../RedemptionPage.styled';

export interface MappedErcBand {
  percentage: number;
  expiryDate: Date;
}

interface ErcSubAccountBreakdownProps {
  subAccountNumber: string;
  ercBands: MappedErcBand[];
}

export const formatLongDate = (date: Date) =>
  Intl.DateTimeFormat('en-GB', { dateStyle: 'long' }).format(date);

function ErcSubAccountBreakdownPanel({
  subAccountNumber,
  ercBands, // Assumes the ERC Bands have been sorted in chronological order
}: ErcSubAccountBreakdownProps) {
  const { ercSubAccountBreakdown } = useContent<RedemptionContent>();
  const {
    currentPercentLabel,
    upcomingPercentLabel,
    ercFreeLabel,
    breakdownIntro,
  } = ercSubAccountBreakdown;

  if (!ercBands.length) {
    return null;
  }

  return (
    <Box>
      <Paragraph>{breakdownIntro}</Paragraph>
      <ErcBreakdownList data-testid={`${subAccountNumber}-erc-list`}>
        {ercBands.map(({ percentage, expiryDate }, idx) => (
          <ErcBreakdownItem
            data-testid={`${subAccountNumber}-band-${idx}`}
            key={`erc-${subAccountNumber}-${percentage}-${expiryDate}`}
          >
            <ErcBreakdownItemIcon>
              <IconPercent trim color="brand" iconSize="md" />
            </ErcBreakdownItemIcon>
            <ErcBreakdownItemDescription>
              <Paragraph marginBottom="none">
                {idx === 0 ? currentPercentLabel : upcomingPercentLabel}
              </Paragraph>
              <Strong data-testid={`${subAccountNumber}-band-${idx}-date`}>
                {`${percentage}% until ${formatLongDate(expiryDate)}`}
              </Strong>
            </ErcBreakdownItemDescription>
          </ErcBreakdownItem>
        ))}
        <ErcBreakdownItem data-testid={`${subAccountNumber}-band-erc-free`}>
          <ErcBreakdownItemIcon>
            <IconPositive trim color="success" iconSize="md" />
          </ErcBreakdownItemIcon>
          <ErcBreakdownItemDescription>
            <Paragraph marginBottom="none">{ercFreeLabel}</Paragraph>
            <Strong data-testid={`${subAccountNumber}-band-erc-free-date`}>
              {formatLongDate(ercBands[ercBands.length - 1].expiryDate)}
            </Strong>
          </ErcBreakdownItemDescription>
        </ErcBreakdownItem>
      </ErcBreakdownList>
    </Box>
  );
}

export default ErcSubAccountBreakdownPanel;
