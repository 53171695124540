import React from 'react';

import { Strong } from '@constellation/core';

import { AppContent } from '../AppContent';

const duplicateStatement: AppContent['duplicateStatement'] = {
  pageHeading: 'Request a duplicate statement',
  errorNotificationBody: {
    line1: `We're sorry but we're having some technical problems. Please try again later.`,
    line2: (
      <>
        If you find the same issue when you come back, please call us on{' '}
        <Strong>0345 300 2627.</Strong>
      </>
    ),
    line3: `Our lines are open Monday - friday 8am - 8pm, Saturday 9am - 1pm. We're closed on Sundays and Bank Holidays.`,
  },
  openingSection: {
    firstParagraph: `We'll send you a mortgage statement each year on the anniversary of when you took out your mortgage.`,
    secondParagraph: `If you’d like a copy statement, you can order one on this page. You’ll need to select ‘Annual’ for the type of statement you want.`,
  },
  interimStatementSection: {
    heading: `Interim statement`,
    paragraphContent: `If you’d only like a statement covering the last 12 months’ transactions, you’ll need to select  ‘Interim’.`,
  },
  whatYouNeedToKnow: {
    heading: `What you need to know`,

    listItems: [
      `You’ll need your 14-digit mortgage account number.`,
      `You should receive your copy statement within 5 working days.`,
      `We’ll send statements to the address we have for you on our system.`,
    ],
  },
  formInstruction: 'Please complete the following information:',
  validationErrorTitle: "Something's not quite right",
  statementTypeField: {
    selectorLabel: 'Which statement type do you want?',
    annualLabel: 'Annual',
    interimLabel: 'Interim',
    error: "Please confirm which statement you'd like to be sent.",
  },
  confirmationCheckboxText:
    'I confirm that I am named on this account and would like you to send this information to the correspondence address for the mortgaged property.',
  confirmationCheckboxError: 'Please confirm',
};
export default duplicateStatement;
