import React, { useEffect } from 'react';

import {
  Text,
  ButtonPattern,
  ContentGroup,
  Heading,
  Hr,
  Link,
  Main,
  Notification,
  Paragraph,
  Button,
  Grid,
  GridItem,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import * as routes from 'client/routes/manifest';
import OnceMadePaymentAccordion from 'client/routes/redemption/components/OnceMadePaymentAccordion/OnceMadePaymentAccordion';
import { logTealiumButtonClickEvent, logTealiumNavEvent } from 'client/tealium';

import { MakePaymentPhoneContent } from './MakePaymentPhonePage.config';
import { StyledContainer } from '../MakePaymentPage.styled';

function MakePaymentPhonePage() {
  const {
    backButtonLabel,
    heading,
    notification,
    makePaymentSection,
    returnToOverviewLink,
  } = useContent<MakePaymentPhoneContent>();
  const navigate = useNavigate();

  useEffect(() => {
    logTealiumNavEvent(
      'Redemption - Pay by phone',
      '3',
      undefined,
      'Pre-Application',
    );
  }, []);

  return (
    <Main>
      <StyledContainer>
        <Link
          as="button"
          onClick={() => {
            logTealiumButtonClickEvent('button/back');
            navigate(routes.MakePayment);
          }}
          iconPosition="left"
          data-testid="back-button-link"
        >
          {backButtonLabel}
        </Link>
      </StyledContainer>
      <Hr />

      <Grid>
        <GridItem xs={12} lg={8}>
          <Heading as="h1" size="s5" data-testid="page-heading">
            {heading}
          </Heading>

          <Notification
            sentiment="information"
            data-testid="limit-notification"
          >
            <Paragraph data-testid="limit-notification-paragraph-1">
              {notification.paragraph1}
            </Paragraph>
            <Paragraph
              marginBottom="none"
              data-testid="limit-notification-paragraph-2"
            >
              {notification.paragraph2}
            </Paragraph>
          </Notification>

          <ContentGroup marginBottom="none">
            <Heading as="h2" size="s2" data-testid="make-payment-heading">
              {makePaymentSection.heading}
            </Heading>

            <Paragraph data-testid="make-payment-paragraph-1">
              <Text>{makePaymentSection.paragraph1}</Text>
              <Link
                href={`tel:${makePaymentSection.contactPhoneNumber.replace(/\s/g, '')}`}
                data-testid="make-payment-phone-link"
                onClick={() => {
                  logTealiumButtonClickEvent('link/helpline');
                }}
              >
                {makePaymentSection.contactPhoneNumber}
              </Link>
              <Text>{makePaymentSection.afterContactPhoneNumber}</Text>
            </Paragraph>

            <Paragraph data-testid="make-payment-paragraph-2">
              {makePaymentSection.paragraph2}
            </Paragraph>
          </ContentGroup>

          <OnceMadePaymentAccordion />
          <ButtonPattern>
            <Button
              iconPosition="left"
              onClick={() => {
                logTealiumButtonClickEvent(
                  'button/return-to-mortgage-overview',
                );
                navigate(routes.MortgageOverview);
              }}
              data-testid="return-to-overview-button"
            >
              {returnToOverviewLink}
            </Button>
          </ButtonPattern>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default MakePaymentPhonePage;
