import React, { useState } from 'react';

import {
  Button,
  Dialog,
  Heading,
  IconHome,
  Text,
  Link,
  Paragraph,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { DifferenceIndicator } from 'client/components/differenceIndicator';
import { Overview } from 'client/services/types/overview';
import { useAppSelector } from 'client/store';
import { logTealiumButtonClickEvent } from 'client/tealium';

import { PropertyBox, StyledIconContainer } from './PropertyPanel.styled';
import { MortgageOverviewPageContent } from '../../MortgageOverviewPage.config';
import { formatCurrency } from '../../utils/formatCurrency';
import formatFirstLineAddress from '../../utils/formatFirstLineAddress';

function PropertyPanel() {
  const { propertyValue, propertyPanel } =
    useContent<MortgageOverviewPageContent>();
  const state = useAppSelector<Overview>((appState) => appState.overview);

  const [propertyValueDialogOpen, setPropertyValueDialogOpen] = useState(false);

  const { overview } = state || {};

  const {
    estimatedPropertyValue,
    valueChangeSincePurchaseInPercent,
    valueChangeSincePurchase,
    securityAddress,
  } = overview || {
    estimatedPropertyValue: 0,
    valueChangeSincePurchase: 0,
    valueChangeSincePurchaseInPercent: 0,
    securityAddress: {
      houseNumber: '',
      addressLine1: '',
    },
  };

  const { houseNumber, addressLine1 } = securityAddress;

  return (
    <>
      {propertyValueDialogOpen && (
        <Dialog
          open={propertyValueDialogOpen}
          data-testid="property-value-modal"
          title={propertyValue.title}
          onClose={() => setPropertyValueDialogOpen(false)}
        >
          <Paragraph data-testid="property-value-dialog-paragraph-1">
            {propertyValue.paragraph1}
          </Paragraph>
          <Paragraph data-testid="property-value-dialog-paragraph-2">
            {propertyValue.paragraph2}
          </Paragraph>
          <Button
            variation="primary"
            data-testid="property-value-dialog-okay-button"
            onClick={() => setPropertyValueDialogOpen(false)}
          >
            {propertyValue.okayButtonLabel}
          </Button>
        </Dialog>
      )}
      <PropertyBox data-testid="overview-property-panel">
        <StyledIconContainer>
          <IconHome iconSize="lg" />
        </StyledIconContainer>
        <Heading
          as="h2"
          size="s4"
          marginTop="02"
          marginBottom="05"
          data-testid="overview-property-address-line-1"
        >
          {formatFirstLineAddress(houseNumber, addressLine1)}
        </Heading>
        <Text marginBottom="01">
          <Link
            as="button"
            data-testid="property-value-dialog-link"
            onClick={() => {
              setPropertyValueDialogOpen(true);
              logTealiumButtonClickEvent('button/estimatedpropertyvalue');
            }}
          >
            {propertyPanel.propertyValueLabel}
          </Link>
        </Text>
        <Text
          size="s5"
          weight="bold"
          as="p"
          marginBottom="05"
          data-testid="overview-estimated-value"
        >
          {formatCurrency(estimatedPropertyValue, 0)}
        </Text>
        <DifferenceIndicator
          data-testid="overview-estimated-value"
          amount={valueChangeSincePurchase}
          percentageChange={valueChangeSincePurchaseInPercent.toFixed(2)}
        />
      </PropertyBox>
    </>
  );
}

export default PropertyPanel;
