import React from 'react';

import { Box, Heading, Paragraph, Text } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import styled from 'styled-components';

import { RedemptionContent } from '../../RedemptionPage.config';
import { formatCurrency } from '../../utils/formatCurrency';
import formatRedemptionDateObject from '../../utils/formatRedemptionDateObject';

const StyledParagraph = styled(Paragraph)`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }: any) => theme.breakpoint_sm}) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const StyledBox = styled(Box)`
  @media (min-width: ${({ theme }: any) => theme.breakpoint_sm}) {
    max-width: 400px;
  }
`;

function RedemptionBreakdownPanel({
  redemptionDate,
  balanceAmount,
  closingAdminCharge,
  mortgageERCAmount,
}) {
  const { breakdownBox } = useContent<RedemptionContent>();
  const { heading, balanceLabel, adminChargeLabel, ercLabel } = breakdownBox;

  return (
    <StyledBox>
      <Heading as="h2" size="s2">
        {heading}
      </Heading>
      <StyledParagraph>
        <Text>{`${balanceLabel} ${formatRedemptionDateObject(redemptionDate)}:`}</Text>
        <Text weight="bold" data-testid="redemption-breakdown-balance">
          {formatCurrency(balanceAmount)}
        </Text>
      </StyledParagraph>
      <StyledParagraph>
        <Text>{adminChargeLabel}</Text>
        <Text weight="bold" data-testid="redemption-breakdown-admin-charge">
          {formatCurrency(closingAdminCharge)}
        </Text>
      </StyledParagraph>
      <StyledParagraph marginBottom="01">
        <Text>{ercLabel}</Text>
        <Text weight="bold" data-testid="redemption-breakdown-ercs">
          {formatCurrency(mortgageERCAmount)}
        </Text>
      </StyledParagraph>
    </StyledBox>
  );
}

export default RedemptionBreakdownPanel;
