import { pxToRem } from '@constellation/core';
import { Link } from '@interstellar/react-app-routing';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.color_action_foreground_secondary_default};

  background: none;
  border: none;
  border-radius: ${({ theme }) => theme.link_border_radius};
  color: ${({ theme }) => theme.color_action_foreground_secondary_default};
  cursor: pointer;
  outline: ${pxToRem(2)}rem solid transparent;
  outline-offset: -${pxToRem(2)}rem;
  padding: 0;
  text-decoration: underline;
  text-underline-offset: ${({ theme }) => theme.link_text_underline_offset};
  transition-duration: ${({ theme }) => theme.transition_duration_default};
  transition-property: color, background-color;
  transition-timing-function: ${({ theme }) =>
    theme.transition_easing_standard_css};

  &:hover {
    color: ${({ theme }) => theme.link_color_hover};
    text-decoration: none;
  }

  &:focus:not(:active) {
    background: ${({ theme }) => theme.link_color_background_focus};
    box-shadow: ${({ theme }) => theme.link_box_shadow_focus_css};
    color: ${({ theme }) => theme.link_color_focus};
  }
`;

export const StyledLinkContainer = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
