import React from 'react';

import { ErrorSummary, ErrorSummaryItem } from '@constellation/core';

interface ErrorBuilderProps {
  errors: object;
  isVisible: boolean;
  errorTitle: string;
}

function ErrorSummaryBuilder({
  errors,
  isVisible,
  errorTitle,
}: ErrorBuilderProps) {
  if (isVisible) {
    return (
      <ErrorSummary title={errorTitle} data-testid="error-summary-box">
        {Object.entries(errors).map(([fieldError, fieldErrorValue]) => (
          <ErrorSummaryItem
            key={`validation-item-${fieldError}`}
            href={`#${fieldError}-error`}
            data-testid={`${fieldError}-summary-error`}
          >
            {fieldErrorValue}
          </ErrorSummaryItem>
        ))}
      </ErrorSummary>
    );
  }
  return null;
}
export default ErrorSummaryBuilder;
