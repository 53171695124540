let csrfToken: string;

export function initCsrfToken(token: string) {
  if (!token) throw new Error('CSRF - no token provided');
  csrfToken = token;
}

export function getCsrfToken() {
  return csrfToken;
}

export function destroyCsrfToken() {
  csrfToken = null;
}

if (window.appConfig.csrf) {
  initCsrfToken(window.appConfig.csrf);
}
