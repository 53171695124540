import { AppContent } from '../AppContent';

const signOutModal: AppContent['signOutModal'] = {
  signOut: {
    confirmModal: {
      title: 'Are you sure you want to sign out?',
      cancelButtonLabel: 'Cancel',
      confirmButtonLabel: 'Sign out',
    },
    buttonLabel: 'Sign out',
  },
};

export default signOutModal;
