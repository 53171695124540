import { createSlice } from '@reduxjs/toolkit';
import { AuthState } from 'client/services/types/overview';

const auth = createSlice({
  name: 'auth',
  initialState: { isLoggedIn: false } as AuthState,
  reducers: {
    login: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.isLoggedIn = true;
    },
    logout: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.isLoggedIn = false;
    },
  },
});

export const { login, logout } = auth.actions;

export default auth;
