import React from 'react';

import { Dialog, Paragraph, Text } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import { RedemptionContent } from '../../RedemptionPage.config';
import formatRedemptionDateObject from '../../utils/formatRedemptionDateObject';

interface RedemptionDateObject {
  day: string;
  month: string;
  year: string;
}

interface TotalCostDialogProps {
  open?: boolean;
  onClose: () => void;
  redemptionDate: RedemptionDateObject;
}

function TotalCostDialog({
  open,
  onClose,
  redemptionDate,
}: TotalCostDialogProps) {
  const { totalCostDialog } = useContent<RedemptionContent>();

  return (
    <Dialog
      title={totalCostDialog.heading}
      open={open}
      onClose={onClose}
      data-testid="total-cost-dialog"
    >
      <Paragraph>
        <Text>{totalCostDialog.paragraph1a}</Text>
        <Text data-testid="total-cost-dialog-redemption-date">
          {formatRedemptionDateObject(redemptionDate)}
        </Text>
        <Text>{totalCostDialog.paragraph1b}</Text>
      </Paragraph>
      <Paragraph marginBottom="none">{totalCostDialog.paragraph2}</Paragraph>
    </Dialog>
  );
}

export default TotalCostDialog;
