import React, { ReactNode, useEffect, useState } from 'react';

import { AnimatedContainer, InnerContainer } from './HideWithTransition.styled';

interface HideWithTransitionProps {
  durationMs: number; // milliseconds
  isVisible: boolean;
  children: ReactNode;
}

function HideWithTransition({
  durationMs,
  isVisible,
  children,
}: HideWithTransitionProps) {
  const [isInDom, setIsInDom] = useState(isVisible);

  // effect waits for transition animation to complete
  // before removing the children from the dom
  useEffect(() => {
    if (isVisible) {
      setIsInDom(true);
      return undefined;
    }

    const timeoutId = setTimeout(() => {
      setIsInDom(false);
    }, durationMs);

    return () => clearTimeout(timeoutId);
  }, [isVisible, durationMs]);

  return isInDom ? (
    <AnimatedContainer isVisible={isVisible} durationMs={durationMs}>
      <InnerContainer>{children}</InnerContainer>
    </AnimatedContainer>
  ) : null;
}

export default HideWithTransition;
