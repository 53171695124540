import { AppContent } from 'content/AppContent';

const signOut: AppContent['signOut'] = {
  title: `You've signed out`,
  feedbackText: `We'd love to hear what you think.`,
  link: {
    url: 'https://lloydsbankinggroup.eu.qualtrics.com/jfe/form/SV_07lOersw6HAG5tY',
    text: 'Give us your feedback'
  },
  signInButtonLabel: 'Sign in again',
};

export default signOut;
