import { AppContent } from 'content/AppContent';

export const appLayout: AppContent['appLayout'] = {
  header: {
    safeAndSecureSection: {
      text: 'Safe & Secure',
    },
    accessibleLogoDescription: {
      text: 'Birmingham Midshires Logo',
    },
  },
  footer: {
    links: {
      cookies: {
        text: 'Cookies',
        url: 'https://www.bmmortgages.co.uk/cookies-policy',
      },
      accessibility: {
        text: 'Accessibility',
        url: 'https://www.bmmortgages.co.uk/accessibility',
      },
      termsAndConditions: {
        text: 'Terms & Conditions',
        url: 'https://www.bmmortgages.co.uk/existing-customers/terms',
      },
      privacy: {
        text: 'Privacy',
        url: 'https://www.bmmortgages.co.uk/privacy-policy',
      },
    },
  },
};
