import React from 'react';

import { Strong } from '@constellation/core';

import { AppContent } from '../AppContent';

const mortgageInterest: AppContent['mortgageInterest'] = {
  pageHeading: 'Request a Certificate of Mortgage Interest',
  errorNotificationHeading: `This service isn't available at the moment`,
  errorNotificationBody: {
    line1: `We're sorry but we're having some technical problems. Please try again later.`,
    line2: (
      <>
        If you find the same issue when you come back, please call us on{' '}
        <Strong>0345 300 2627.</Strong>
      </>
    ),
    line3: `Our lines are open Monday - friday 8am - 8pm, Saturday 9am - 1pm. We're closed on Sundays and Bank Holidays.`,
  },
  openingSection: {
    firstParagraph:
      'If you would like to request a Certificate of Mortgage Interest, please complete the below form and you will receive this within 5 working days.',
  },
  whatYouNeedToKnowSection: {
    heading: 'What you need to know',

    listItems: [
      'This form is for UK Tax Certificates only.',
      'You can only request a certificate for the previous tax year.',
      'We can only deliver to the correspondence address we currently hold on our system.',
    ],
  },
  formInstruction: 'Please complete the following information:',
  sentAnnuallyText:
    'Would you like this to be sent annually after each UK Tax year?',
  validationErrorTitle: "Something's not quite right",
  confirmationCheckboxSection: {
    confirmationMessage:
      'I confirm that I am named on this mortgage account and would like you to send a Certificate of Interest to the correspondence address for the mortgaged property.',
    confirmationCheckboxText: 'Submit',
    confirmationCheckboxError: 'Please Confirm',
  },

  formErrors: {
    sentAnnuallyError: 'Please confirm annual statement preference',
  },
};

export default mortgageInterest;
