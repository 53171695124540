const formatCurrency = (value: string) => {
  if (!value) {
    return value;
  }

  if (value === '.' || value === ',' || value === '£') {
    return '';
  }

  const strippedValue = value.replace(/[^0-9.,£]/g, '');

  if (strippedValue === '') {
    return strippedValue;
  }

  if (strippedValue.slice(-2) === '..') {
    return strippedValue.slice(0, strippedValue.length - 1);
  }

  if (strippedValue.slice(-1) === '.') {
    if (strippedValue.split('.').length > 2) {
      return strippedValue.slice(0, strippedValue.length - 1);
    }
    return strippedValue;
  }

  const valueStrippedCommas = value.replace(/[£,]/g, '');
  const toFormat = parseFloat(valueStrippedCommas);

  return Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(toFormat);
};

export default formatCurrency;
