import React, { useEffect, useState } from 'react';

import {
  Heading,
  Main,
  Checkbox,
  Button,
  List,
  ListItem,
  Paragraph,
  Spinner,
  Notification,
  Link,
  Selector,
  SelectorItem,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useLocation, useNavigate } from '@interstellar/react-app-routing';
import { ErrorSummaryBuilder } from 'client/components/errorSummaryBuilder';
import { useFormMutation } from 'client/services/api';
import {
  getJourneyStep,
  logTealiumButtonClickEvent,
  logTealiumNavEvent,
} from 'client/tealium';
import isValidAccountNumber from 'client/validation/isValidAccountNumber';
import isValidPostcode from 'client/validation/isValidPostcode';
import isValidTelephoneNumber from 'client/validation/isValidTelephoneNumber';
import { Field, Form, Formik } from 'formik';

import {
  DuplicateRequestContent,
  DuplicateStatementContent,
} from './DuplicateStatementPage.config';
import * as routes from '../../manifest';
import RequestForm from '../components/requestForm/RequestForm';
import { RequestFormContent } from '../components/requestForm/RequestForm.config';

interface FormValues {
  title: string;
  firstName: string;
  lastName: string;
  address: string;
  postcode: string;
  accountNumber: string;
  telephone: string;
  confirmationCheckbox: boolean;
  statementType: string;
}

const hasEmptyFields = (values) =>
  Object.values(values).some((value) => value === '');

export default function DuplicateStatementPage() {
  const location = useLocation();
  useEffect(() => {
    logTealiumNavEvent(
      'Statement request forms',
      getJourneyStep(location),
      undefined,
      'Pre-Application',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    pageHeading,
    openingSection,
    interimStatementSection,
    whatYouNeedToKnow,
    formInstruction,
    statementTypeField,
  } = useContent<DuplicateStatementContent>();

  const {
    errorNotificationHeading,
    errorNotificationBody,
    confirmationCheckboxText,
    confirmationCheckboxError,
    validationErrorTitle,
  } = useContent<DuplicateRequestContent>();

  const requestForm = useContent<RequestFormContent>();

  const validateForm = (values: FormValues) => {
    const errors: Partial<{
      title: string;
      firstName: string;
      lastName: string;
      address: string;
      postcode: string;
      accountNumber: string;
      telephone: string;
      statementType: string;
      confirmationCheckbox: string;
    }> = {};
    if (!values.title) {
      errors.title = requestForm.errors.title;
    }

    if (!values.firstName) {
      errors.firstName = requestForm.errors.firstName;
    }

    if (!values.lastName) {
      errors.lastName = requestForm.errors.lastName;
    }

    if (!values.accountNumber) {
      errors.accountNumber = requestForm.errors.accountNumber;
    }

    if (!values.address) {
      errors.address = requestForm.errors.address;
    }

    if (!values.postcode) {
      errors.postcode = requestForm.errors.postcode;
    }

    if (!values.telephone) {
      errors.telephone = requestForm.errors.telephone;
    }

    if (!values.statementType) {
      errors.statementType = statementTypeField.error;
    }

    if (!values.confirmationCheckbox) {
      errors.confirmationCheckbox = confirmationCheckboxError;
    }

    if (!isValidAccountNumber(values.accountNumber)) {
      errors.accountNumber = requestForm.errors.invalidAccountNumber;
    }

    if (!isValidTelephoneNumber(values.telephone)) {
      errors.telephone = requestForm.errors.invalidTelephone;
    }

    if (!isValidPostcode(values.postcode)) {
      errors.postcode = requestForm.errors.invalidPostcode;
    }

    return errors;
  };

  const navigate = useNavigate();

  const [showSubmissionError, setShowSubmissionError] =
    useState<boolean>(false);
  const [showValidationError, setShowValidationError] =
    useState<boolean>(false);

  const [form, { isLoading: isFormLoading }] = useFormMutation();

  return (
    <Main>
      {isFormLoading && <Spinner />}
      <Link
        iconPosition="left"
        as="button"
        data-testid="back-button-link"
        onClick={() => {
          logTealiumButtonClickEvent('button/back');
          navigate(routes.MortgageOverview);
        }}
      >
        Back
      </Link>

      <Heading as="h1" size="s7" data-testid="duplicate-statement-page-heading">
        {pageHeading}
      </Heading>

      <Formik
        initialValues={{
          title: '',
          firstName: '',
          lastName: '',
          address: '',
          postcode: '',
          accountNumber: '',
          telephone: '',
          confirmationCheckbox: false,
          statementType: '',
        }}
        validate={validateForm}
        onSubmit={async (values: FormValues) => {
          const submitSuccessful = await form({
            personalDetails: { ...values },
          });

          if ('data' in submitSuccessful) {
            navigate(routes.SuccessfulRequest);
          } else {
            setShowSubmissionError(true);
            window.scrollTo({ top: 140, behavior: 'smooth' });
          }
        }}
      >
        {({ values, touched, errors, isValid, handleBlur }) => (
          <Form noValidate>
            <ErrorSummaryBuilder
              errors={errors}
              isVisible={showValidationError && !isValid}
              errorTitle={validationErrorTitle}
            />

            {showSubmissionError && (
              <Notification
                heading={errorNotificationHeading}
                sentiment="critical"
              >
                <Paragraph>{errorNotificationBody.line1}</Paragraph>
                <Paragraph>{errorNotificationBody.line2}</Paragraph>
                <Paragraph>{errorNotificationBody.line3}</Paragraph>
              </Notification>
            )}
            <Paragraph data-testid="opening-section-first-paragraph">
              {openingSection.firstParagraph}
            </Paragraph>
            <Paragraph data-testid="opening-section-second-paragraph">
              {openingSection.secondParagraph}
            </Paragraph>

            <Heading size="s3" data-testid="interim-statement-section-heading">
              {interimStatementSection.heading}
            </Heading>
            <Paragraph
              marginTop="05"
              data-testid="interim-statement-section-paragraph-content"
            >
              {interimStatementSection.paragraphContent}
            </Paragraph>

            <Heading size="s3" data-testid="what-you-need-to-know-heading">
              {whatYouNeedToKnow.heading}
            </Heading>

            <List>
              {whatYouNeedToKnow.listItems.map((item, index) => (
                <ListItem
                  data-testid={`what-you-need-to-know-list-item-${index + 1}`}
                  key={item}
                >
                  {item}
                </ListItem>
              ))}
            </List>

            <Paragraph data-testid="form-instruction">
              {formInstruction}
            </Paragraph>
            <RequestForm
              handleBlur={handleBlur}
              values={values}
              touched={touched}
              errors={errors}
              requestFormFieldLabels={requestForm}
            />

            <Field name="statementType">
              {({ field }) => (
                <Selector
                  data-testid="request-form-statement-type"
                  name={field.name}
                  label={statementTypeField.selectorLabel}
                  variation="Spaced-Auto"
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  error={touched.statementType && errors.statementType}
                >
                  <SelectorItem
                    id="statement-type-annual"
                    data-testid="request-form-statement-type-annual"
                    name="statementType"
                    value="annual"
                    onClick={() =>
                      logTealiumButtonClickEvent('button/statement-type-annual')
                    }
                  >
                    {statementTypeField.annualLabel}
                  </SelectorItem>
                  <SelectorItem
                    id="statement-type-interim"
                    data-testid="request-form-statement-type-interim"
                    name="statementType"
                    value="interim"
                    onClick={() =>
                      logTealiumButtonClickEvent(
                        'button/statement-type-interim',
                      )
                    }
                  >
                    {statementTypeField.interimLabel}
                  </SelectorItem>
                </Selector>
              )}
            </Field>

            <Field
              name="confirmationCheckbox"
              as={Checkbox}
              label={confirmationCheckboxText}
              error={
                touched.confirmationCheckbox && errors.confirmationCheckbox
              }
              value={values.confirmationCheckbox}
              data-testid="request-form-confirmation-checkbox"
              marginBottom="04"
              onClick={() => {
                logTealiumButtonClickEvent('button/confirm');
              }}
            />

            <Button
              type="submit"
              data-testid="request-form-submit-button"
              onClick={() => {
                logTealiumButtonClickEvent('button/submit');
                if (!isValid || hasEmptyFields(values)) {
                  setShowValidationError(true);
                  window.scrollTo({ top: 140, behavior: 'smooth' });
                }
              }}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Main>
  );
}
