import React, { ReactElement, useState } from 'react';

import { ContentProvider } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import birminghamMidshiresLogo from 'assets/common/birmingham-midshires-negative.png';
import useAppConfig from 'client/components/appConfig/useAppConfig';
import { AppLayout } from 'client/components/appLayout';
import { BrandProvider, Brand } from 'client/components/brandProvider';
import { VersionMismatchDialog } from 'client/components/versionMismatchDialog';
import content from 'client/content';
import Routes from 'client/routes';
import { registerErrorHandler } from 'client/services/axiosInterceptors';
import { getThemeFromUrl } from 'client/utils/getThemeFromUrl';

import ScrollToTop from '../scrollToTop/ScrollToTop';
import { SessionManager } from '../sessionManager';


function App(): ReactElement {
  const configBrand = useAppConfig().BRAND_NAME;
  const brand: Brand = getThemeFromUrl() || configBrand;
  const navigate = useNavigate();

  const [initialisedInterceptors, setInitialisedInterceptors] = useState(false);

  const [showVersionMismatchDialog, setShowVersionMismatchDialog] =
    useState(false);

  if (!initialisedInterceptors) {
    registerErrorHandler(navigate, setShowVersionMismatchDialog);
    setInitialisedInterceptors(true);
  }

  return (
    <BrandProvider brand={brand}>
      <AppLayout logo={birminghamMidshiresLogo}>
        <ScrollToTop />
        <Routes />
        <ContentProvider value={content({ brand }).sessionManager}>
          <SessionManager />
        </ContentProvider>
        <ContentProvider value={content({ brand }).versionMismatchDialog}>
          <VersionMismatchDialog
            showVersionMismatchDialog={showVersionMismatchDialog}
            setShowVersionMismatchDialog={setShowVersionMismatchDialog}
          />
        </ContentProvider>
      </AppLayout>
    </BrandProvider>
  );
}

export default App;
