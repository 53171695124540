import React, { useEffect } from 'react';

import {
  ContentGroup,
  Heading,
  Link,
  Text,
  IconChevronLeft,
  Paragraph,
  Hr,
  Strong,
  Main,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useLocation, useNavigate } from '@interstellar/react-app-routing';
import {
  getJourneyStep,
  logTealiumButtonClickEvent,
  logTealiumNavEvent,
} from 'client/tealium';
import styled from 'styled-components';

import { OverpaymentsPageContent } from './OverpaymentsPage.config';
import * as routes from '../manifest';

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing_05};
`;

export default function OverpaymentsPage() {
  const location = useLocation();

  useEffect(() => {
    logTealiumNavEvent(
      'Overpayments',
      getJourneyStep(location),
      undefined,
      'Pre-Application',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { title, bankTransfer, phone } = useContent<OverpaymentsPageContent>();

  const navigate = useNavigate();

  return (
    <Main>
      <StyledContainer>
        <Link
          icon={<IconChevronLeft trim />}
          iconPosition="left"
          as="button"
          data-testid="back-button-link"
          onClick={() => {
            logTealiumButtonClickEvent('button/back');
            navigate(routes.MortgageOverview);
          }}
        >
          Back
        </Link>
      </StyledContainer>

      <Heading as="h1" size="s7">
        {title}
      </Heading>

      <ContentGroup marginBottom="none">
        <Heading as="h2" size="s3">
          {bankTransfer.title}
        </Heading>

        <Paragraph>{bankTransfer.para1}</Paragraph>
        <Paragraph>{bankTransfer.para2}</Paragraph>
        <Paragraph>{bankTransfer.para3}</Paragraph>

        <Heading as="h2" size="s2" marginBottom="none">
          {bankTransfer.bankTitle}
        </Heading>

        <Text as="p" size="s2">
          {bankTransfer.bankSortCode}
          <Strong>{bankTransfer.bankSortCodeValue}</Strong>
        </Text>

        <Text as="p" size="s2">
          {bankTransfer.bankAccNo}
          <Strong>{bankTransfer.bankAccNoValue}</Strong>
        </Text>

        <Text as="p" size="s2">
          {bankTransfer.bankName}
          <Strong>{bankTransfer.bankNameValue}</Strong>
        </Text>

        <Text as="p" size="s2" marginBottom="05">
          {bankTransfer.bankRef}
          <Strong>{bankTransfer.bankRefValue}</Strong>
        </Text>

        <Paragraph>{bankTransfer.para4}</Paragraph>
      </ContentGroup>

      <Hr />

      <ContentGroup marginBottom="none">
        <Heading as="h2" size="s3">
          {phone.title}
        </Heading>

        <Paragraph>
          {phone.para1A}
          <Strong>{phone.para1B}</Strong>
          {phone.para1C}
          <Strong>{phone.para1D}</Strong>
          {phone.para1E}
        </Paragraph>
        <Paragraph>{phone.para2}</Paragraph>
        <Paragraph>{phone.para3}</Paragraph>
        <Paragraph>{phone.para4}</Paragraph>
      </ContentGroup>

      <StyledContainer>
        <Link
          icon={<IconChevronLeft trim />}
          iconPosition="left"
          as="button"
          data-testid="bottom-back-button-link"
          onClick={() => {
            logTealiumButtonClickEvent('button/back');
            navigate(routes.MortgageOverview);
          }}
        >
          Back
        </Link>
      </StyledContainer>
    </Main>
  );
}
