import axios from 'axios';

import { HEADER_JS_CLIENT_VERSION, HEADER_CSRF_TOKEN } from './axiosConstants';
import { getCsrfToken } from '../utils/csrf/csrf';

const csrfToken = getCsrfToken();

const axiosInstance = axios.create({
  baseURL: window.appConfig.apiBasePath || 'http://localhost:3000',
  headers: {
    common: {
      'Content-Type': 'application/json',
      [HEADER_CSRF_TOKEN]: csrfToken,
      [HEADER_JS_CLIENT_VERSION]: window.appConfig.jsClientVersion || '',
    },
  },
  withCredentials: true,
});

export default axiosInstance;
