import { AppContent } from '../AppContent';

const rentalYieldCalculator: AppContent['rentalYieldCalculator'] = {
  backButtonLabel: 'Back',
  whyLinkLabel: 'Why calculate your rental yield?',
  whyCalculateDialog: {
    title: 'Why calculate your rental yield?',
    paragraph1:
      'Rental yield helps you figure out how much income you can earn from investing in a rental property.',
    paragraph2: "It's a good estimate of:",
    list: [
      'how much you can earn from investing in rental property',
      'the rent you should  be charging',
      "how much a property's costs can affect your income.",
    ],
  },
  considerationsDialog: {
    title: 'Things to consider in your annual running costs',
    paragraph1: 'You might want to consider:',
    list: [
      'agent fees',
      'maintenance fees',
      'property repairs',
      'insurance',
      'council tax',
      'utility bills',
      'ground rent and service charges',
      'void periods.',
    ],
    paragraph2:
      'Think about other things a property could cost you over a year and include them in this total. ',
  },
  pageHeading: 'Rental yield calculator',
  supportingText:
    "Rental yield is how much you can earn from your property each year. It's shown as two percentages of the property's value - the gross and net yield. Gross gives you the total rental income. Net gives you a figure after taking your costs and fees into consideration.",

  propertyPurchasePriceField: {
    label: 'Property purchase price (or current market value)',
    error: 'Tell us the property purchase price or its market value',
  },
  monthlyRentalIncomeField: {
    label: 'Monthly rental income',
    error: 'Tell us the monthly rental income',
  },
  annualRunningCostsField: {
    label: 'Annual running costs (Optional)',
    supportiveText:
      "If you don't tell us this we can only give you the gross yield.",
    whatToIncludeLinkLabel: 'What to include here',
  },
  buttonLabel: 'Calculate',
  alternateButtonLabel: 'Recalculate',
  grossYieldPanel: {
    title: 'Gross yield',
    supportiveText: 'Without running costs',
  },
  netYieldPanel: {
    title: 'Net yield',
    supportiveText: 'With running costs',
  },
};

export default rentalYieldCalculator;
