import React from 'react';

import { Strong } from '@constellation/core';

import { AppContent } from '../AppContent';

const debitChangeRequest: AppContent['debitChangeRequest'] = {
  errorNotificationHeading: `This service isn't available at the moment`,
  errorNotificationsBody: {
    line1: `We're sorry but we're having some technical problems. Please try again later.`,
    line2: (
      <>
        If you find the same issue when you come back, please call us on{' '}
        <Strong>0345 300 2627.</Strong>
      </>
    ),
    line3: `Our lines are open Monday - Friday 8am - 8pm, Saturday 9am - 1pm. We're closed on Sundays and Bank Holidays.`,
  },
  pageHeading: `Request a change to your Direct Debit due date`,
  openingSection: {
    firstParagraph: `If you would like to change the date you pay your mortgage, simply complete the below form and you'll receive confirmation of your new payment date within 5 working days by post.`,
  },
  whatYouNeedToKnow: {
    heading: `What you need to know`,
    listItems: [
      `Your chosen payment date needs to be between the 1st and 28th of the month.`,
      `If we have already requested your payment at the time of completing this form, the change will take place from the following month.`,
      `Direct Debit payments are requested 5 days before the due date e.g. If you pay your mortgage on the 20th of the month, we would request the funds on the 15th.`,
      `Depending on your current and new payment dates, you could make 2 payments in a short period of time.`,
      `Your monthly payment amount may rise or fall after you change the date we collect the money from you.`,
      `We can only deliver to the correspondence address we currently hold on our system.`,
    ],
  },
  formInstruction: `Please complete the following information:`,
  newDateLabel: `What would you like your new mortgage payment date to be?`,
  submitButtonText: `Submit`,
  confirmationCheckboxText: `I confirm that I am named on this mortgage account and give you permission to change the Direct Debit date as instructed in this form.`,
  confirmationCheckboxError: `Please confirm`,
  newDateError: `Please confirm a new monthly payment date between the 1st and 28th`,
  validationErrorTitle: "Something's not quite right",
};

export default debitChangeRequest;
