import React, { useEffect } from 'react';

import {
  Button,
  Main,
  Heading,
  ContentGroup,
  Paragraph,
  Link,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useLocation, useNavigate } from '@interstellar/react-app-routing';
import useAppConfig from 'client/components/appConfig/useAppConfig';
import { useSignOutMutation } from 'client/services/api';
import { logout } from 'client/slices/auth';
import { clear } from 'client/slices/overview';
import { useAppDispatch, useAppSelector } from 'client/store';
import {
  getJourneyStep,
  logTealiumButtonClickEvent,
  logTealiumNavEvent,
} from 'client/tealium';

import { SignOutContent } from './SignOutPage.config';
import * as routes from '../manifest';

function SignOutPage() {
  const navigate = useNavigate();
  const content = useContent<SignOutContent>();
  const { title, signInButtonLabel, feedbackText, link } = content;
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const [signout] = useSignOutMutation();
  const dispatch = useAppDispatch();
  const appConfig = useAppConfig();
  const location = useLocation();

  useEffect(() => {
    logTealiumNavEvent(
      'Sign out',
      location?.state?.previousPath
        ? getJourneyStep({ pathname: location.state.previousPath })
        : '1',
      undefined,
      'Pre-Application',
    );

    if (isLoggedIn) {
      signout();
      dispatch(clear());
      dispatch(logout());
      document.cookie = `${appConfig?.cookieName ?? '__Secure_BM_DC_ST'}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${window.location.hostname}; Path=/; SameSite=Strict; Secure`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Main>
      <Heading as="h1">{title}</Heading>
      <Paragraph marginBottom="none" data-testid="sign-out-paragraph-text">
        {' '}
        {feedbackText}{' '}
      </Paragraph>
      <Link
        href={link.url}
        external
        data-testid="sign-out-feedback-link"
        onClick={() => logTealiumButtonClickEvent('button/giveusyourfeedback')}
      >
        {link.text}
      </Link>
      <ContentGroup marginTop="05">
        <Button
          onClick={() => {
            navigate(routes.SignIn);
            logTealiumButtonClickEvent('button/signin');
          }}
        >
          {signInButtonLabel}
        </Button>
      </ContentGroup>
    </Main>
  );
}

export default SignOutPage;
