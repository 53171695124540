const formatRedemptionDateObject = ({
  day,
  month,
  year,
}: {
  day: string;
  month: string;
  year: string;
}) => `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year.slice(-2)}`;

export default formatRedemptionDateObject;
