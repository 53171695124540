import React from 'react';

import {
  Heading,
  Paragraph,
  Notification,
  Link,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import { RedemptionContent } from '../../RedemptionPage.config';

interface NextStepsSectionProps {
  onRequestPaperStatementClick: () => void;
}

function NextStepsSection({
  onRequestPaperStatementClick,
}: NextStepsSectionProps) {
  const { nextStepsSection } = useContent<RedemptionContent>();

  const {
    heading,
    readyToPaySection,
    notReadyToPaySection,
    beforePayingNotification,
  } = nextStepsSection;

  return (
    <>
      <Heading as="h2" size="s4" data-testid="next-steps-heading">
        {heading}
      </Heading>

      <Heading as="h3" size="s2" data-testid="ready-to-pay-heading">
        {readyToPaySection.heading}
      </Heading>
      <Paragraph data-testid="ready-to-pay-paragraph-1">
        {readyToPaySection.paragraph1}
      </Paragraph>

      <Heading as="h3" size="s2" data-testid="not-ready-to-pay-heading">
        {notReadyToPaySection.heading}
      </Heading>
      <Paragraph data-testid="not-ready-to-pay-paragraph-1">
        {notReadyToPaySection.paragraph1}
      </Paragraph>
      <Paragraph data-testid="not-ready-to-pay-paragraph-2">
        {notReadyToPaySection.paragraph2}
      </Paragraph>

      <Paragraph data-testid="not-ready-to-pay-paragraph-3">
        <Text>{notReadyToPaySection.paragraph3a}</Text>
        <Link
          as="button"
          data-testid="request-paper-statement-link"
          onClick={onRequestPaperStatementClick}
        >
          {notReadyToPaySection.requestPaperStatementLink}
        </Link>
        <Text>{notReadyToPaySection.paragraph3b}</Text>
      </Paragraph>

      <Notification
        sentiment="warning"
        heading={beforePayingNotification.heading}
        data-testid="before-paying-notification"
      >
        <Paragraph data-testid="before-paying-notification-paragraph-1">
          {beforePayingNotification.paragraph1}
        </Paragraph>
        <Paragraph data-testid="before-paying-notification-paragraph-2">
          {beforePayingNotification.paragraph2}
        </Paragraph>
        <Paragraph
          data-testid="before-paying-notification-paragraph-3"
          marginBottom="02"
        >
          {beforePayingNotification.paragraph3}
        </Paragraph>
      </Notification>
    </>
  );
}

export default NextStepsSection;
