import { Box } from '@constellation/core';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing_05};
`;

export const StyledBox = styled(Box)`
  text-align: center;
`;
