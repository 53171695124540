import requestForm from './requestForm';
import { AppContent } from '../AppContent';

const redemptionStatement: AppContent['redemptionStatement'] = {
  backButtonLabel: 'Back',
  pageTitle: 'Request a Redemption Statement',
  validationErrorTitle: "Something's not quite right",
  requestInformation: {
    openingParagraph: `If you would like to know the outstanding balance on your mortgage. We can arrange to send you a Mortgage Redemption Statement in the post.`,
    secondParagraph: `If you'd  like to receive in the post. We can calculate to the month end or alternatively a specific date, fill in the fields below and  'Send' and you will receive one within 5 working days.`,
    listHeading: 'What you need to know',
    sectionListItems: [
      `You'll need to quote your mortgage account number. This is a 14-digit number.`,
      `We'll post your redemption statement within 5 working days.`,
      `We can only deliver to the correspondence address we currently hold on our system.`,
    ],
    addressInfo: '<Correspondence address info TBC>',
    nextActionsInfo: 'Please complete the following information:',
  },
  requestForm,
  chosenDateText: 'Date if not month end',
  chosenDateError: 'Please enter a valid date',
  confirmationCheckboxText:
    'I can confirm that I am named on this account and I am requesting you to send this information to my property address.',
  confirmationCheckboxError: 'Please confirm',
};

export default redemptionStatement;
