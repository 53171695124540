const isLeapYear = (year: number): boolean =>
  (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

const isValidDate = (day: number, month: number, year: number): boolean => {
  /* Work out if a valid date has been entered:
    1. Feb can only have 28 days unless a leap year then 29
    2. Apr, June, Sept and Nov can only have 30 days */
  if (year < 1 || month < 1 || month > 12 || day < 1) {
    return false;
  }

  const daysInMonth: number[] = [
    31,
    isLeapYear(year) ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ];

  if (day > daysInMonth[month - 1]) {
    return false;
  }

  return true;
};

export default isValidDate;
