import { Box } from '@constellation/core';
import styled from 'styled-components';

export const ContentContainer = styled(Box)`
  display: flex;
  background-color: white !important;
`;

export const StyledIconContainer = styled.div`
  span {
    margin-right: 1rem;
    justify-content: flex-start !important;
    color: ${({ theme }) => theme.color_source_secondary_1} !important;
  }
`;
