import { AppContent } from '../AppContent';

const makePayment: AppContent['makePayment'] = {
  backButtonLabel: 'Back',
  heading: 'How do you want to pay?',
  bankTransferCard: {
    title: 'Bank transfer',
    info: 'Transfer money from a current account',
  },
  byPhoneCard: {
    title: 'By phone',
    info: 'Pay using our automated phone line',
  },
};

export default makePayment;
