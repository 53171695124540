import React from 'react';

import { IconLightBulb, Paragraph, Link } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { BoxWithIcon } from 'client/components/boxWithIcon';
import { logTealiumButtonClickEvent } from 'client/tealium';

import { MortgageOverviewPageContent } from '../../MortgageOverviewPage.config';

function EnergyEfficiencyPanel() {
  const { energyEfficiencyPanel } = useContent<MortgageOverviewPageContent>();

  return (
    <BoxWithIcon
      icon={<IconLightBulb iconSize="lg" trim />}
      data-testid="overview-energy-efficiency-panel"
    >
      <Paragraph marginBottom="01">{energyEfficiencyPanel.text}</Paragraph>
      <Link
        href={energyEfficiencyPanel.link.url}
        external
        data-testid="overview-energy-efficiency-plan-link"
        onClick={() => logTealiumButtonClickEvent('button/getyouractionplan')}
      >
        {energyEfficiencyPanel.link.text}
      </Link>
    </BoxWithIcon>
  );
}

export default EnergyEfficiencyPanel;
