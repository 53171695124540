import React from 'react';

import { StyledHr } from './DottedHr.styled';
import { Margin } from '../types/margin';

interface DottedHrProps {
  marginTop?: Margin;
  marginBottom?: Margin;
}

function DottedHr({ marginTop = '06', marginBottom = '06' }: DottedHrProps) {
  return <StyledHr $marginTop={marginTop} $marginBottom={marginBottom} />;
}

export default DottedHr;
