import React, { useEffect, useState } from 'react';

import { Button, ButtonPattern, Dialog, Paragraph } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useLocation, useNavigate } from '@interstellar/react-app-routing';
import { useSignOutMutation } from 'client/services/api';
import { logout } from 'client/slices/auth';
import { clear } from 'client/slices/overview';
import { useAppDispatch } from 'client/store';
import { useIdleTimer } from 'react-idle-timer';

import { SessionManagerContent } from './SessionManager.config';
import * as routes from '../../routes/manifest';
import useAppConfig from '../appConfig/useAppConfig';

const nudgeTimeout = 5 * 60 * 1000;
const expiryTimeout = 20 * 60 * 1000;

const UNAUTHENTICATED_ROUTES = [
  routes.SignIn,
  routes.SignedOut,
  routes.Error,
  routes.ServiceUnavailable,
];

function SessionManager() {
  const navigate = useNavigate();
  const location = useLocation();
  const { expiryModal, nudgeModal } = useContent<SessionManagerContent>();
  const [showNudgeModal, setShowNudgeModal] = useState(false);
  const [showExpiryModal, setShowExpiryModal] = useState(false);
  const dispatch = useAppDispatch();
  const appConfig = useAppConfig();
  const [signout] = useSignOutMutation();

  const handleNudge = () => {
    setShowNudgeModal(true);
  };

  const clearSessionAndLogout = () => {
    signout();
    dispatch(clear());
    dispatch(logout());
    document.cookie = `${appConfig?.cookieName ?? '__Secure_BM_DC_ST'}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${window.location.hostname}; Path=/; SameSite=Strict; Secure`;
    navigate(routes.SignedOut, { state: { previousPath: location.pathname } });
  };

  const handleExpiry = () => {
    setShowNudgeModal(false);
    setShowExpiryModal(true);
    clearSessionAndLogout();
  };

  const idleTimer = useIdleTimer({
    timeout: expiryTimeout,
    promptBeforeIdle: nudgeTimeout,
    onIdle: handleExpiry,
    onPrompt: handleNudge,
  });

  useEffect(() => {
    if (UNAUTHENTICATED_ROUTES.includes(location.pathname)) {
      idleTimer.pause();
    } else {
      idleTimer.start();
    }
  }, [location, idleTimer]);

  const handleCloseNudgeModal = () => {
    idleTimer.reset();
    setShowNudgeModal(false);
  };

  const handleSignOut = () => {
    idleTimer.reset();
    setShowNudgeModal(false);
    clearSessionAndLogout();
  };

  const handleCloseExpiryModal = () => {
    setShowExpiryModal(false);
  };

  if (showExpiryModal) {
    return (
      <Dialog
        open={showExpiryModal}
        title={expiryModal.title}
        onClose={handleCloseExpiryModal}
        data-testid="expiry-dialog"
      >
        <Paragraph>{expiryModal.bodyText}</Paragraph>
        <ButtonPattern marginBottom="none">
          <Button
            variation="primary"
            onClick={() => {
              navigate(routes.SignIn, {
                state: { previousPath: location.pathname },
              });
              setShowExpiryModal(false);
            }}
            data-testid="expiry-dialog-sign-in-button"
          >
            {expiryModal.signBackInButtonLabel}
          </Button>
        </ButtonPattern>
      </Dialog>
    );
  }

  if (showNudgeModal) {
    return (
      <Dialog
        open={showNudgeModal}
        title={nudgeModal.title}
        onClose={handleCloseNudgeModal}
        data-testid="nudge-dialog"
      >
        <Paragraph weight="bold">{nudgeModal.bodyTextPara1}</Paragraph>
        <Paragraph>{nudgeModal.bodyTextPara2} </Paragraph>
        <ButtonPattern marginBottom="none">
          <Button
            variation="primary"
            onClick={handleCloseNudgeModal}
            data-testid="nudge-dialog-continue-button"
          >
            {nudgeModal.staySignedInButtonLabel}
          </Button>
          <Button
            variation="secondary"
            onClick={handleSignOut}
            data-testid="nudge-dialog-sign-out-button"
          >
            {nudgeModal.signOutButtonLabel}
          </Button>
        </ButtonPattern>
      </Dialog>
    );
  }
}

export default SessionManager;
