import React, { useEffect, useState } from 'react';

import {
  Main,
  Button,
  Heading,
  Checkbox,
  Spinner,
  Paragraph,
  Strong,
  ListItem,
  List,
  Notification,
  Link,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useLocation, useNavigate } from '@interstellar/react-app-routing';
import { ErrorSummaryBuilder } from 'client/components/errorSummaryBuilder';
import { useFormMutation } from 'client/services/api';
import {
  getJourneyStep,
  logTealiumButtonClickEvent,
  logTealiumNavEvent,
} from 'client/tealium';
import isValidAccountNumber from 'client/validation/isValidAccountNumber';
import isValidPostcode from 'client/validation/isValidPostcode';
import isValidTelephoneNumber from 'client/validation/isValidTelephoneNumber';
import { Field, Form, Formik } from 'formik';

import { OverpaymentsRequestContent } from './OverpaymentsRequestPage.config';
import * as routes from '../../manifest';
import RequestForm from '../components/requestForm/RequestForm';
import { RequestFormContent } from '../components/requestForm/RequestForm.config';

interface FormValues {
  title: string;
  firstName: string;
  lastName: string;
  address: string;
  postcode: string;
  accountNumber: string;
  telephone: string;
  confirmationCheckbox: boolean;
}

export default function OverpaymentsRequestPage() {
  const location = useLocation();

  useEffect(() => {
    logTealiumNavEvent(
      'Statement request forms',
      getJourneyStep(location),
      undefined,
      'Pre-Application',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [form, { isLoading: isFormLoading }] = useFormMutation();

  const navigate = useNavigate();
  const [showSubmissionError, setShowSubmissionError] =
    useState<boolean>(false);
  const [showValidationError, setShowValidationError] =
    useState<boolean>(false);

  const {
    pageTitle,
    requestInformation,
    validationErrorTitle,
    confirmationCheckboxText,
    confirmationCheckboxError,
  } = useContent<OverpaymentsRequestContent>();
  const requestForm = useContent<RequestFormContent>();

  const {
    formErrorNotification: { errorNotificationHeading, errorNotificationBody },
  } = requestForm;

  const validateForm = (values: FormValues) => {
    const errors: Partial<{
      title: string;
      firstName: string;
      lastName: string;
      address: string;
      postcode: string;
      accountNumber: string;
      telephone: string;
      confirmationCheckbox: string;
    }> = {};

    if (!values.title) {
      errors.title = requestForm.errors.title;
    }

    if (!values.firstName) {
      errors.firstName = requestForm.errors.firstName;
    }

    if (!values.lastName) {
      errors.lastName = requestForm.errors.lastName;
    }

    if (!values.address) {
      errors.address = requestForm.errors.address;
    }

    if (!values.postcode) {
      errors.postcode = requestForm.errors.postcode;
    } else if (!isValidPostcode(values.postcode)) {
      errors.postcode = requestForm.errors.invalidPostcode;
    }

    if (!values.accountNumber) {
      errors.accountNumber = requestForm.errors.postcode;
    } else if (!isValidAccountNumber(values.accountNumber)) {
      errors.accountNumber = requestForm.errors.invalidAccountNumber;
    }

    if (!values.telephone) {
      errors.telephone = requestForm.errors.telephone;
    } else if (!isValidTelephoneNumber(values.telephone)) {
      errors.telephone = requestForm.errors.invalidTelephone;
    }

    if (!values.confirmationCheckbox) {
      errors.confirmationCheckbox = confirmationCheckboxError;
    }

    return errors;
  };

  const hasEmptyFields = (values: FormValues) =>
    Object.values(values).some((value) => value === '');

  return (
    <Main>
      {isFormLoading && <Spinner />}
      <Link
        iconPosition="left"
        as="button"
        data-testid="back-button-link"
        onClick={() => {
          logTealiumButtonClickEvent('button/back');
          navigate(routes.MortgageOverview);
        }}
      >
        Back
      </Link>
      <Heading
        as="h1"
        size="s7"
        marginTop="05"
        data-testid="overpayments-allowance-page-heading"
      >
        {pageTitle}
      </Heading>
      <Formik
        initialValues={{
          title: '',
          firstName: '',
          lastName: '',
          address: '',
          postcode: '',
          accountNumber: '',
          telephone: '',
          confirmationCheckbox: false,
        }}
        validate={validateForm}
        onSubmit={async (values: FormValues) => {
          const submitSuccessful = await form({
            personalDetails: { ...values },
          });

          if ('data' in submitSuccessful) {
            navigate(routes.SuccessfulRequest);
          } else {
            setShowSubmissionError(true);
            window.scrollTo({ top: 140, behavior: 'smooth' });
          }
        }}
      >
        {({ values, touched, errors, isValid, handleBlur }) => (
          <Form noValidate>
            <ErrorSummaryBuilder
              errors={errors}
              isVisible={showValidationError && !isValid}
              errorTitle={validationErrorTitle}
            />
            {showSubmissionError && (
              <Notification
                heading={errorNotificationHeading}
                sentiment="critical"
                data-testid="redemption-statement-submission-error-notification"
              >
                <Paragraph>{errorNotificationBody.errorMessageLine1}</Paragraph>
                <Paragraph>{errorNotificationBody.errorMessageLine1}</Paragraph>
                <Paragraph>{errorNotificationBody.errorMessageLine1}</Paragraph>
              </Notification>
            )}
            <Paragraph>{requestInformation.openingParagraph}</Paragraph>
            <Strong>{requestInformation.listHeading}</Strong>
            <List>
              {requestInformation.sectionListItems.map((item) => (
                <ListItem key={item}>{item}</ListItem>
              ))}
            </List>
            <Paragraph>{requestInformation.nextActionsInfo}</Paragraph>

            <RequestForm
              handleBlur={handleBlur}
              values={values}
              touched={touched}
              errors={errors}
              requestFormFieldLabels={requestForm}
            />
            <Field
              name="confirmationCheckbox"
              as={Checkbox}
              label={confirmationCheckboxText}
              error={
                touched.confirmationCheckbox && errors.confirmationCheckbox
              }
              value={values.confirmationCheckbox}
              data-testid="request-form-confirmation-checkbox"
              marginBottom="04"
              onClick={() => {
                logTealiumButtonClickEvent('button/confirm');
              }}
            />
            <Button
              type="submit"
              data-testid="request-form-submit-button"
              onClick={() => {
                logTealiumButtonClickEvent('button/submit');
                if (!isValid || hasEmptyFields(values)) {
                  setShowValidationError(true);
                  window.scrollTo({ top: 140, behavior: 'smooth' });
                }
              }}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Main>
  );
}
