const isValidAccountNumber = (accountNumber: string) => {
  if (!accountNumber || accountNumber.length !== 14) return false;

  const prefix = accountNumber.substring(0, 2);

  if (prefix !== '60') {
    return false;
  }

  const regExp = /^[0-9]+$/;

  if (!regExp.test(accountNumber)) {
    return false;
  }

  return true;
};

export default isValidAccountNumber;
