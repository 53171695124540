import { AppContent } from '../AppContent';

const versionMismatchDialog: AppContent['versionMismatchDialog'] = {
  title: 'Reload required',
  para1: 'To continue using the app, please reload the page.',
  para2:
    "We've made some important updates that require a refresh to take effect. Thank you for understanding.",
  buttonLabel: 'Reload now',
};

export default versionMismatchDialog;
