import styled from 'styled-components';

interface CapsuleProps {
  $positive: boolean;
}

export const Capsule = styled.div<CapsuleProps>`
  display: inline-block;
  border-radius: ${({ theme }) => theme.typography_text_font_size_s2};
  background-color: ${({ theme, $positive }) =>
    $positive ? theme.color_source_green_100 : theme.color_source_red_100};
  padding-top: ${({ theme }) => theme.spacing_01};
  padding-bottom: ${({ theme }) => theme.spacing_01};
  padding-left: ${({ theme }) => theme.spacing_02};
  padding-right: ${({ theme }) => theme.spacing_03};
  width: auto;
`;
