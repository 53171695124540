import React from 'react';

import { Button, ButtonPattern, Dialog, Paragraph } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import { VersionMismatchDialogContent } from './VersionMismatchDialog.config';

interface VersionMismatchDialogProps {
  showVersionMismatchDialog: boolean;
  setShowVersionMismatchDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

function VersionMismatchDialog({
  showVersionMismatchDialog,
  setShowVersionMismatchDialog,
}: VersionMismatchDialogProps) {
  const content = useContent<VersionMismatchDialogContent>();

  if (showVersionMismatchDialog) {
    return (
      <Dialog
        open={showVersionMismatchDialog}
        title={content.title}
        onClose={() => setShowVersionMismatchDialog(false)}
        data-testid="version-mismatch-dialog"
      >
        <Paragraph>{content.para1}</Paragraph>
        <Paragraph>{content.para2}</Paragraph>
        <ButtonPattern marginBottom="none">
          <Button
            variation="primary"
            onClick={() => {
              setShowVersionMismatchDialog(false);
              window.location.reload();
            }}
            data-testid="version-mismatch-dialog-reload-button"
          >
            {content.buttonLabel}
          </Button>
        </ButtonPattern>
      </Dialog>
    );
  }

  return null;
}

export default VersionMismatchDialog;
