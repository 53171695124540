import React from 'react';

import { Notification } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import { RedemptionContent } from '../../RedemptionPage.config';

function ErcNoChargeNotification() {
  const { ercSubAccountBreakdown } = useContent<RedemptionContent>();
  const {
    noChargesNotification: { paragraph1 },
  } = ercSubAccountBreakdown;

  return (
    <Notification sentiment="success" data-testid="erc-no-charge-notification">
      {paragraph1}
    </Notification>
  );
}

export default ErcNoChargeNotification;
