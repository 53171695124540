import React, { useEffect } from 'react';

import { Grid, GridItem, Heading, Main, Spinner } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useLocation } from '@interstellar/react-app-routing';
import { useOverviewMutation } from 'client/services/api';
import type { Overview } from 'client/services/types/overview';
import { useAppSelector } from 'client/store';
import { getJourneyStep, logTealiumNavEvent } from 'client/tealium';

import { AccountPanel } from './components/accountPanel';
import { EnergyEfficiencyPanel } from './components/energyEfficiencyPanel';
import { FeedbackPanel } from './components/feedbackPanel';
import { FutureFeaturesNotification } from './components/futureFeaturesNotification';
import { LoanToValuePanel } from './components/loanToValuePanel';
import { MortgageInfoPanel } from './components/mortgageInfoPanel';
import { OverpaymentsPanel } from './components/overpaymentsPanel';
import { PaymentHelpPanel } from './components/paymentHelpPanel';
import { PropertyPanel } from './components/propertyPanel';
import { RedemptionPanel } from './components/redemptionPanel';
import RentalYieldPanel from './components/rentalYieldPanel/RentalYieldPanel';
import { RequestsPanel } from './components/requestsPanel';
import { WhatsNewDialog } from './components/whatsNewDialog';
import { MortgageOverviewPageContent } from './MortgageOverviewPage.config';
import { BreakoutPage } from './MortgageOverviewPage.styled';

export default function MortgageOverviewPage() {
  const [postOverview, { isLoading, isError, data }] = useOverviewMutation({
    fixedCacheKey: 'overview',
  });

  const state = useAppSelector<Overview>((appState) => appState.overview);
  const { welcomeMessage } = useContent<MortgageOverviewPageContent>();
  const location = useLocation();

  const { requestFormsEnabled } = window.appConfig;

  const { futureFeaturesNotificationEnabled } = window.appConfig;

  const { overview } = state || {};
  const {
    firstForename,
    mortgageAccountNumber,
    eligibleToSwitch,
    eligibleToBorrowMore,
  } = overview || {
    firstForename: '',
    mortgageAccountNumber: '',
    eligibleToSwitch: false,
    eligibleToBorrowMore: false,
  };

  useEffect(() => {
    if (!state?.overview) {
      return;
    }

    const aOrBVersion = window.appConfig?.abVersion ? 'B' : 'A';
    const ptEligible = eligibleToSwitch ? 'PT: Eligible' : 'PT: Ineligible';
    const fadEligible = eligibleToBorrowMore
      ? 'FAD: Eligible'
      : 'FAD: Ineligible';

    logTealiumNavEvent(
      `Your mortgage overview ${aOrBVersion} - ${ptEligible} ${fadEligible}`,
      getJourneyStep(location),
      undefined,
      'Pre-Application',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // Refetch the auth data if we've refreshed the page and its not cached
  useEffect(() => {
    const refetchOverview = async () => {
      await postOverview();
    };

    if (!data && !state.overview) {
      refetchOverview();
    }
  }, [data, state, postOverview]);

  if (!overview || isLoading || isError) {
    return (
      <Main>
        <Spinner />
      </Main>
    );
  }

  return (
    <BreakoutPage>
      <WhatsNewDialog />
      <AccountPanel />
      <Main>
        <Heading as="h1" data-testid="overview-welcome">
          {welcomeMessage} {firstForename}
        </Heading>
        {futureFeaturesNotificationEnabled ? (
          <FutureFeaturesNotification
            mortgageAccountNumber={mortgageAccountNumber}
          />
        ) : null}

        <Grid>
          <GridItem sm={12} md={8}>
            <PropertyPanel />
            <LoanToValuePanel />
            <OverpaymentsPanel />
            <RedemptionPanel />
            <RentalYieldPanel />
            <MortgageInfoPanel />
            {requestFormsEnabled && <RequestsPanel />}
          </GridItem>
          <GridItem sm={12} md={4}>
            <EnergyEfficiencyPanel />
            {/* don't show FeedbackPanel & FutureFeatureNotification at
                them same time, both have a link to the feedback form */}
            {futureFeaturesNotificationEnabled ? null : <FeedbackPanel />}
            <PaymentHelpPanel />
          </GridItem>
        </Grid>
      </Main>
    </BreakoutPage>
  );
}
