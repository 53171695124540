const formatIntegerOrDecimalCurrency = (value: string) => {
  if (value === '' || value === '£') return value;

  const strippedValue = value.replace(/[^0-9.]/g, '');

  const toFormat = parseFloat(strippedValue);

  if (toFormat % 1 !== 0) {
    return Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(toFormat);
  }

  return Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(toFormat);
};

export default formatIntegerOrDecimalCurrency;
