import React, { useEffect } from 'react';

import {
  ContentGroup,
  Heading,
  Link,
  Main,
  Paragraph,
} from '@constellation/core';
import { ChevronLeft } from '@constellation/core/icons';
import { useContent } from '@interstellar/react-app-content';
import { useLocation, useNavigate } from '@interstellar/react-app-routing';
import {
  getJourneyStep,
  logTealiumButtonClickEvent,
  logTealiumNavEvent,
} from 'client/tealium';

import { BorrowMoreContent } from './BorrowMorePage.config';
import * as routes from '../manifest';

function BorrowMorePage() {
  const location = useLocation();

  useEffect(() => {
    logTealiumNavEvent(
      'Borrow More',
      getJourneyStep(location),
      undefined,
      'Pre-Application',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { backButtonLabel, title, para1, para2Title, link, riskStatement } =
    useContent<BorrowMoreContent>();
  const navigate = useNavigate();

  return (
    <Main>
      <Link
        as="button"
        icon={<ChevronLeft />}
        iconPosition="left"
        onClick={() => {
          logTealiumButtonClickEvent('button/back');
          navigate(routes.MortgageOverview);
        }}
        data-testid="borrow-more-back-button"
      >
        {backButtonLabel}
      </Link>
      <ContentGroup>
        <Heading as="h1" size="s7" marginTop="05" marginBottom="07">
          {title}
        </Heading>
        <Paragraph>{para1}</Paragraph>
        <Heading as="h2" marginBottom="01" size="s3">
          {para2Title}
        </Heading>
        <Paragraph>
          <Link
            external
            href={link.url}
            data-testid="borrow-more-find-broker-link"
            onClick={() => {
              logTealiumButtonClickEvent('button/searchforonehere');
            }}
          >
            {link.text}
          </Link>
        </Paragraph>
        <Paragraph>{riskStatement}</Paragraph>
      </ContentGroup>
    </Main>
  );
}

export default BorrowMorePage;
