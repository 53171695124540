import { AppContent } from '../AppContent';

const redemptionAlreadyRequested: AppContent['redemptionAlreadyRequested'] = {
  notification: {
    heading: "We can't show your amount owing figure for this date.",
    text: "You've already requested a paper statement. This should be with you within 5 working days.",
  },
  returnLinkLabel: 'Return to mortgage overview',
};

export default redemptionAlreadyRequested;
