import { AppContent } from '../AppContent';

const serviceUnavailable: AppContent['serviceUnavailable'] = {
  title: 'Service unavailable',
  box: {
    title: "This service isn't available at the moment",
    para1: "Please try again later.",
    para2: 'If you find the same issue when you come back, please call us on ',
    para2Strong: '0345 300 2627.',
    para3:
      "Our lines are open Monday - Friday 8am - 8pm, Saturday 9am - 1pm. We're closed on Sundays and Bank Holidays.",
  },
  applicationHours: {
    title: 'Application hours',
    mainText:
      'So we can provide you with the best possible service, this online application is only available during these times:',
    monToSatHours: 'Monday - Saturday: 6am - 10pm',
    sunHours: 'Sunday: 6am - 9pm',
  },
};

export default serviceUnavailable;
