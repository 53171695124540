import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import api from 'client/services/api';
import { Overview } from 'client/services/types/overview';

const overview = createSlice({
  name: 'overview',
  initialState: { overview: null } as Overview,
  reducers: {
    clear: () => ({ overview: null }),
  },
  extraReducers: (builder: ActionReducerMapBuilder<Overview>) => {
    builder.addMatcher(
      api.endpoints.overview.matchFulfilled,
      (state, { payload }) => {
        // eslint-disable-next-line no-param-reassign
        state.overview = payload.overview;
      },
    );
  },
});

export const { clear } = overview.actions;
export default overview;
