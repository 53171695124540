const isPast = (date: Date) => {
  const today = new Date();

  // ignore time
  return (
    date < new Date(today.getFullYear(), today.getMonth(), today.getDate())
  );
};

export default isPast;
