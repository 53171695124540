import styled from 'styled-components';

import { Margin } from '../types/margin';

interface StyledHrProps {
  $marginTop: Margin;
  $marginBottom: Margin;
}

export const getSpacing = (theme: any, spacing: Margin) =>
  spacing === 'none' ? '0' : theme[`spacing_${spacing}`];

export const StyledHr = styled.hr<StyledHrProps>`
  padding: 2px;
  height: 1px;
  background-image: radial-gradient(
    circle at 5px,
    rgb(173, 173, 173) 1px,
    rgba(255, 255, 255, 0) 2px
  );
  margin-top: ${({ theme, $marginTop }) => getSpacing(theme, $marginTop)}
  margin-bottom: ${({ theme, $marginBottom }) => getSpacing(theme, $marginBottom)};
  margin-left: 0;
  margin-right: 0;
  margin-block-start: ${({ theme, $marginTop }) => getSpacing(theme, $marginTop)};
  margin-block-end: ${({ theme, $marginTop }) => getSpacing(theme, $marginTop)};
  margin-inline-start: auto;
  margin-inline-end: auto;

  background-position: bottom;
  background-size:
    10px 4px,
    4px 10px;
  background-repeat: repeat-x, repeat-y;
  border: none;
  display: block;
`;
