/**
 * This utility wraps all function within an object with a try catch
 * and will not rethrow errors and stop the application from
 * falling over if an error occurs with any of the functions
 */
export const recursiveErrorCatchingProxy = (object) =>
  new Proxy(object, {
    get(target, prop) {
      const value = target[prop];

      if (typeof value === 'function') {
        return (...args) => {
          try {
            return value.apply(target, args);
          } catch (e) {
            // Do not rethrow
            // eslint-disable-next-line no-console
            console.error(e);
            return undefined;
          }
        };
      }

      if (typeof value === 'object' && value !== null) {
        return recursiveErrorCatchingProxy(value);
      }

      return value;
    },
  });
