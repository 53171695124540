import isValidDate from './isValidDate';

const isValidDob = (day: string, month: string, year: string) => {
  if (!day || !month || !year) return false;
  if (day.trim() === '' || month.trim() === '' || year.trim() === '')
    return false;

  const dayInt = parseInt(day, 10);
  const monthInt = parseInt(month, 10);
  const yearInt = parseInt(year, 10);

  if (yearInt < 1900) return false;
  if (dayInt <= 0 || monthInt <= 0) return false;

  if (!isValidDate(dayInt, monthInt, yearInt)) {
    return false;
  }

  const prefixedMonth = monthInt.toString().padStart(2, '0');
  const prefixedDay = dayInt.toString().padStart(2, '0');

  const dateOfBirthString = `${year}-${prefixedMonth}-${prefixedDay}`;
  const dateOfBirth = new Date(Date.parse(dateOfBirthString));
  const now = new Date();
  const latestTwentyOneYearOldDate = new Date(
    now.setFullYear(now.getFullYear() - 21),
  );

  if (dateOfBirth > latestTwentyOneYearOldDate) {
    return false;
  }

  return true;
};

export default isValidDob;
