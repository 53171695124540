import React from 'react';

import { Strong, Notification, Text } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import { RedemptionContent } from '../../RedemptionPage.config';
import { formatCurrency } from '../../utils/formatCurrency';

interface ErcChargeNotificationProps {
  redemptionDate: string;
  charge: string;
}

function ErcChargeNotification({
  redemptionDate,
  charge,
}: ErcChargeNotificationProps) {
  const { ercSubAccountBreakdown } = useContent<RedemptionContent>();
  const {
    chargesNotification: { paragraph1a, paragraph1b, paragraph1c },
  } = ercSubAccountBreakdown;

  return (
    <Notification sentiment="warning">
      <Text>{paragraph1a}</Text>
      <Text data-testid="erc-charge-notification-redemption-date">
        {redemptionDate}
      </Text>
      <Text>{paragraph1b}</Text>
      <Strong data-testid="erc-charge-notification-amount">
        {formatCurrency(charge)}
      </Strong>
      <Text>{paragraph1c}</Text>
    </Notification>
  );
}

export default ErcChargeNotification;
