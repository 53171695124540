import React, { useContext } from 'react';

import { ContentProvider } from '@interstellar/react-app-content';
import { FooterComponent } from 'client/components/footer';
import { HeaderComponent } from 'client/components/header';
import content from 'client/content';
import styled from 'styled-components';

import AppLayoutProps from './AppLayout.types';
import BrandContext from '../brandProvider/BrandContext';

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

function AppLayout({ children, logo }: AppLayoutProps): React.JSX.Element {
  const { brand } = useContext(BrandContext);

  return (
    <ContentProvider value={content({ brand }).appLayout}>
      <StyledPage>
        <HeaderComponent logo={logo} />
        {children}
        <FooterComponent />
      </StyledPage>
    </ContentProvider>
  );
}

export default AppLayout;
