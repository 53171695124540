import { Link as ReactRouterLink } from '@interstellar/react-app-routing';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing_05};
`;

export const BoxLink = styled(ReactRouterLink)`
  text-decoration: none;
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing_03};
`;
export const BoxLinkSpan = styled.span`
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.spacing_03};
  padding: ${({ theme }) => theme.spacing_03};
  padding-right: ${({ theme }) => theme.spacing_01};
  background-color: ${({ theme }) => theme.color_source_grey_100};
  width: 100%;
  display: flex;
  align-items: center;

  & :first-child {
    margin-right: ${({ theme }) => theme.spacing_02};
  }

  & :last-child {
    margin-left: auto;
  }
`;

export const BoxLinkTextContainer = styled.span`
  display: flex;
  flex-direction: column;
`;
