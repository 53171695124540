import { recursiveErrorCatchingProxy } from './recursiveErrorCatchingProxy';
import * as routes from '../routes/manifest';

const defaults = {
  JourneyName: 'MortgageStraightThroughProductTransfer',
  JourneyVersion: '1',
  Brand: 'bm',
  Division: 'Retail',
  Presentation: 'Responsive',
  ProductGroup: 'Mortgages',
  ProductSubGroup: '',
  Channel: 'Online',
  Auth: false,
  PageRole: 'Servicing',
  State: 'Unauth',
  Platform: 'UnAuth',
  JourneyProduct: 'Mortgage',
  JourneyTariff: 'MortgageTracker',
  JourneyStep: '1',
  JourneyStepName: 'Straight Through Product Transfer',
  ApplicationState: 'Application',
};

/**
 * @example // Passing enum
 * await logTealiumNavEvent('SubAccounts', StepNumber.SUB_ACCOUNTS, undefined, 'Sub Accounts', 'Application');
 * @example // TIMED_OUT & TECHNICAL_ERROR:
 * // use the code from the page which this occurs
 * @link
 * [analytics](https://confluence.devops.lloydsbanking.com/display/MTGCHG/Investigation+-+Analytics+for+Intermediaries+PT)
 */
const journeyPathMap = {
  [routes.SignIn]: '1',
  [routes.MortgageOverview]: '2',
  [routes.SignedOut]: 'undefined',
  [routes.Overpayments]: '3',
  [routes.ProductTransfer]: '3',
  [routes.BorrowMore]: '3',
  [routes.Error]: 'undefined',
  [routes.DebitChangeRequest]: '4',
  [routes.DuplicateStatement]: '4',
  [routes.MortgageInterest]: '4',
  [routes.RedemptionStatement]: '4',
  [routes.RequestOverpayments]: '4',
};

export const getJourneyStep = (currentLocation) => {
  const path = currentLocation.pathname;
  return journeyPathMap[path];
};

declare global {
  interface Window {
    setAnalyticsVariables;
    runAnalytics;
    LBGAnalytics;
  }
}

let utagData;

let safeAnalytics;

const isTealiumAvailable = () =>
  typeof window !== 'undefined' &&
  typeof window.setAnalyticsVariables === 'function';

const getSafeLBGAnalytics = () => {
  if (isTealiumAvailable() && !safeAnalytics) {
    // eslint-disable-next-line no-restricted-properties
    safeAnalytics = recursiveErrorCatchingProxy(window.LBGAnalytics);
  }

  return safeAnalytics;
};

export function init(brand, journeyName: string) {
  if (!brand) throw new Error('Tealium not initialised - brand not supplied');
  // "utagData.Brand !== brand" Fixes a bug where the brand doesn't change if it's been initialized with a different brand after the first time
  if (!utagData || utagData.Brand !== brand) {
    utagData = {
      ...defaults,
      ...{ Brand: brand, JourneyName: journeyName },
    };
  }
  return utagData;
}

export function getConfig() {
  if (!utagData)
    throw new Error('Tealium not initialised - call init() with brand');
  return utagData;
}

export function logTealiumButtonClickEvent(linkValue: string) {
  if (isTealiumAvailable()) {
    getSafeLBGAnalytics().events.send({
      JourneyEvent: 'Button Click',
      LinkValue: linkValue,
    });
  }
}

export function logAlternateJourneyTealiumEvent() {
  if (isTealiumAvailable()) {
    getSafeLBGAnalytics()
      .data.setJourneyStatus(
        'Alternate Journey',
        'Sent to OAB Journey',
        'Referred',
      )
      .done();
  }
}

export function logTealiumPageSubmitEvent() {
  if (isTealiumAvailable()) {
    getSafeLBGAnalytics().events.pageSubmit().send();
  }
}

export function logTealiumUpdateElementEvent(
  fieldName: string,
  fieldValue: string,
  dwell = null,
) {
  if (isTealiumAvailable()) {
    if (dwell) {
      getSafeLBGAnalytics()
        .events.fieldUpdate(fieldName, dwell)
        .captureInput(fieldName, fieldValue)
        .send();
    } else {
      getSafeLBGAnalytics().events.captureInput(fieldName, fieldValue).send();
    }
  }
}

export function logTealiumBlurEvent(fieldName: string, dwell = 0) {
  if (isTealiumAvailable()) {
    getSafeLBGAnalytics().events.fieldUpdate(fieldName, dwell).send();
  }
}

export function logTealiumTooltipEvent(tooltipText: string) {
  if (isTealiumAvailable()) {
    getSafeLBGAnalytics().events.tooltip(tooltipText).send();
  }
}

export function logTealiumNavEvent(
  journeyStepName: string,
  journeyStep: string,
  journeyActionNarrative: any,
  applicationState: any,
) {
  if (isTealiumAvailable()) {
    getSafeLBGAnalytics()
      .data.setJourneyStep(journeyStep, journeyStepName)
      .setJourneyStatus(undefined, journeyActionNarrative, applicationState)
      .setJourneyProduct(defaults.JourneyProduct, '', '')
      .done()
      .events.pageView()
      .send();
  }
}
