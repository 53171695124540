import React, { useEffect, useState } from 'react';

import {
  Paragraph,
  Grid,
  GridItem,
  ContentGroup,
  Text,
  TextField,
  DateField,
  Button,
  Heading,
  Hr,
  Main,
  Notification,
  Spinner,
  Box,
  Container,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import {
  useLocation,
  Navigate,
  useNavigate,
} from '@interstellar/react-app-routing';
import useAppConfig from 'client/components/appConfig/useAppConfig';
import { ErrorSummaryBuilder } from 'client/components/errorSummaryBuilder';
import { HideWithTransition } from 'client/components/hideWithTransition';
import {
  useOverviewMutation,
  useSignOutMutation,
  useServiceAvailabilityQuery,
} from 'client/services/api';
import { login, logout } from 'client/slices/auth';
import { clear } from 'client/slices/overview';
import { useAppDispatch, useAppSelector } from 'client/store';
import {
  getJourneyStep,
  logTealiumBlurEvent,
  logTealiumNavEvent,
} from 'client/tealium';
import { Field, Form, Formik } from 'formik';

import { SignInPageContent } from './SignInPage.config';
import isValidAccountNumber from '../../validation/isValidAccountNumber';
import isValidDob from '../../validation/isValidDob';
import isValidPostcode from '../../validation/isValidPostcode';
import * as routes from '../manifest';

interface FormValues {
  accountNumber: string;
  dob: {
    day: string;
    month: string;
    year: string;
  };
  postcode: string;
}

export default function SignInPage() {
  const [overview, { isLoading: isLoadingOverview }] = useOverviewMutation({
    fixedCacheKey: 'overview',
  });

  const [signout] = useSignOutMutation();

  const { data, isLoading: isLoadingService } = useServiceAvailabilityQuery();
  const serviceAvailable = data?.serviceAvailable;

  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const {
    title,
    fields,
    signInButtonLabel,
    signOutNotificationText,
    applicationHours,
  } = useContent<SignInPageContent>();
  const navigate = useNavigate();
  const location = useLocation();
  const appConfig = useAppConfig();

  const [showSummaryError, setShowSummaryError] = useState(false);
  const [showAuthError, setShowAuthError] = useState(false);
  const [showLoggedOutNotification, setShowLoggedOutNotification] =
    useState(false);

  useEffect(() => {
    logTealiumNavEvent(
      'Sign in',
      getJourneyStep(location),
      undefined,
      'Pre-Application',
    );

    if (isLoggedIn) {
      setShowLoggedOutNotification(true);
      signout();
      dispatch(clear());
      dispatch(logout());
      document.cookie = `${appConfig?.cookieName ?? '__Secure_BM_DC_ST'}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${window.location.hostname}; Path=/; SameSite=Strict; Secure`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoadingService && !serviceAvailable) {
    return (
      <Navigate
        to={routes.ServiceUnavailable}
        state={{ previousPath: location.pathname }}
      />
    );
  }

  if (isLoadingService) {
    return (
      <Main>
        <Spinner />
      </Main>
    );
  }

  const { accountNumber, dob, postcode, summaryErrors } = fields;

  const hasEmptyFields = (values: FormValues) =>
    Object.values(values).some((value) => value === '');

  const validateForm = (values: FormValues) => {
    const errors: Partial<{
      accountNumber: string;
      dob: string;
      postcode: string;
    }> = {};

    if (
      !isValidAccountNumber(values.accountNumber) ||
      values.accountNumber === ''
    ) {
      errors.accountNumber = accountNumber.error;
    }

    if (!isValidDob(values.dob.day, values.dob.month, values.dob.year)) {
      errors.dob = dob.error;
    }

    if (!isValidPostcode(values.postcode)) {
      errors.postcode = postcode.error;
    }

    return errors;
  };
  let focusTimerStart: number;

  const handleTimerEnd = (fieldName: string) => {
    const focusTimerEnd = Date.now();
    const focusTimerTotal = focusTimerEnd - focusTimerStart;

    logTealiumBlurEvent(fieldName, focusTimerTotal);
  };

  return (
    <Main>
      <ContentGroup marginBottom="none">
        <Formik
          initialValues={{
            accountNumber: '',
            dob: { year: '', month: '', day: '' },
            postcode: '',
          }}
          validate={validateForm}
          onSubmit={async (values: FormValues) => {
            const authed = await overview({
              accountNumber: values.accountNumber,
              dateOfBirth: `${values.dob.year}-${values.dob.month.padStart(2, '0')}-${values.dob.day.padStart(2, '0')}`,
              postcode: values.postcode.toUpperCase(),
            });

            if ('data' in authed) {
              dispatch(login());
              navigate(routes.MortgageOverview);
            } else {
              const isMismatchError =
                (authed.error as { status: number })?.status === 409;

              if (!isMismatchError) {
                setShowAuthError(true);
              }
            }
          }}
          enableReinitialize={false}
        >
          {({
            isValid,
            values,
            touched,
            errors,
            resetForm,
            handleChange,
            setValues,
            handleBlur,
            isSubmitting,
          }) => {
            if (isSubmitting || isLoadingOverview) {
              return <Spinner />;
            }

            return (
              <>
                {showLoggedOutNotification && (
                  <Notification sentiment="critical">
                    {signOutNotificationText}
                  </Notification>
                )}
                <Heading as="h1" size="s7" data-testid="sign-in-heading">
                  {title}
                </Heading>

                <Grid>
                  <GridItem md={8}>
                    <Hr marginTop="none" />

                    <Form noValidate>
                      <HideWithTransition
                        isVisible={showAuthError}
                        durationMs={250}
                      >
                        <Notification sentiment="critical">
                          <Paragraph>{summaryErrors.invalidTitle}</Paragraph>
                          <Paragraph>
                            {summaryErrors.invalidFirstParagraph}
                          </Paragraph>
                          <Paragraph>
                            <Text>{summaryErrors.invalidSecondParagraph}</Text>
                            <Text weight="bold">
                              {summaryErrors.helpPhoneNumber}.
                            </Text>
                          </Paragraph>
                          <Paragraph marginBottom="none">
                            {summaryErrors.invalidThirdParagraph}
                          </Paragraph>
                        </Notification>
                      </HideWithTransition>
                      <ErrorSummaryBuilder
                        errors={errors}
                        isVisible={showSummaryError && !isValid}
                        errorTitle={summaryErrors.defaultTitle}
                      />
                      <Field
                        id="accountNumber"
                        name="accountNumber"
                        as={TextField}
                        maxLength="14"
                        label={accountNumber.label}
                        supportiveText={accountNumber.supportiveText}
                        autoComplete="off"
                        onFocus={() => {
                          focusTimerStart = Date.now();
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                          handleTimerEnd('mortgage account number');
                        }}
                        onChange={(e) => {
                          handleChange(e);
                          setShowAuthError(false);
                        }}
                        error={
                          touched.accountNumber &&
                          errors.accountNumber &&
                          accountNumber.error
                        }
                        data-testid="sign-in-account-number-field"
                      />

                      <Field
                        label={dob.label}
                        name="dob"
                        day={values.dob.day}
                        month={values.dob.month}
                        year={values.dob.year}
                        onChange={(dateOfBirth: {
                          day: string;
                          month: string;
                          year: string;
                        }) => {
                          setValues({ ...values, dob: dateOfBirth });
                          setShowAuthError(false);
                        }}
                        onFocus={() => {
                          focusTimerStart = Date.now();
                        }}
                        onBlur={(e) => {
                          handleBlur(e);

                          // Uses e.target.name to account for subfields (dob-day, dob-month, dob-year). Relies on the Formik field names
                          handleTimerEnd(e.target.name);
                        }}
                        error={
                          (touched.dob ||
                            (touched['dob-day'] &&
                              touched['dob-month'] &&
                              touched['dob-year'])) &&
                          errors.dob &&
                          dob.error
                        }
                        data-testid="sign-in-dob-field"
                        as={DateField}
                      />

                      <Field
                        as={TextField}
                        label={postcode.label}
                        id="postcode"
                        name="postcode"
                        supportiveText={postcode.supportiveText}
                        onFocus={() => {
                          focusTimerStart = Date.now();
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                          handleTimerEnd('postcode');
                        }}
                        onChange={(e) => {
                          handleChange(e);
                          setShowAuthError(false);
                        }}
                        maxLength={8}
                        error={
                          touched.postcode && errors.postcode && postcode.error
                        }
                        data-testid="sign-in-postcode-field"
                      />
                      <Hr />
                      <Container
                        marginBottom="05"
                        marginTop="none"
                        padding="none"
                      >
                        <Button
                          type="submit"
                          onClick={() => {
                            if (!isValid || hasEmptyFields(values)) {
                              resetForm({ values });
                              setShowSummaryError(true);
                              window.scrollTo({ top: 140, behavior: 'smooth' });
                            }
                          }}
                          iconPosition="right"
                        >
                          {signInButtonLabel}
                        </Button>
                      </Container>
                    </Form>
                  </GridItem>

                  <GridItem md={4}>
                    <Box marginBottom="none">
                      <Heading size="s4">{applicationHours.title}</Heading>
                      <Paragraph>{applicationHours.mainText}</Paragraph>
                      <Paragraph>{applicationHours.monToSatHours}</Paragraph>
                      <Paragraph marginBottom="none">
                        {applicationHours.sunHours}
                      </Paragraph>
                    </Box>
                  </GridItem>
                </Grid>
              </>
            );
          }}
        </Formik>
      </ContentGroup>
    </Main>
  );
}
